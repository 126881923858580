import styled from 'styled-components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/es6/pipeable'
import { fold } from 'fp-ts/es6/Either'
import { useHistory } from 'react-router'
import { colors } from '../../theme'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { postSendMagicLinkEmail } from '../../api/api-auth'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import { Button } from '../Button/Button'
import { config } from '../../config'
import { urls } from '../../urls'
import { createUrlWithHost } from '../../utils/url-utils'
import { PostSendMagicLinkEmailRequest } from '../../api/api-types'
import { useErrorContext } from '../../context/ErrorContext'
import { MailIcon } from '../../icons/MailIcon'
import { LeftArrowIcon } from '../../icons/LeftArrowIcon'
import { EMAIL_LOGIN_INPUT_ID, EMAIL_LOGIN_BUTTON_ID } from '../../test-selectors'

function validateEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const EmailContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 1.875rem;
`

const EmailInput = styled.input`
  color: ${colors.HMDBlue};
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  line-height: normal;

  /* chrome autofill styling fix */
  @keyframes autofill {
    0%,
    100% {
      color: #000;
      background: transparent;
    }
  }
  &:-webkit-autofill {
    animation-delay: 1s;
    animation-name: autofill;
    animation-fill-mode: both;
  }
`

const InputContainer = styled.div`
  display: flex;
  border: 2px solid #c2cddb;
  margin-bottom: 0.9375rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
`

export const EmailLogin = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const history = useHistory()
  const { onError } = useErrorContext()
  const magicLinkApi = useDataLoader(postSendMagicLinkEmail)

  const onEmailLogin = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    event.preventDefault()
    const requestData: PostSendMagicLinkEmailRequest = {
      body: {
        email,
        branding: 'Hermes',
        loginData: {
          uuid: null,
          program: 'Hermes',
          referer: null,
          userAgent: window.navigator.userAgent,
          ipAddress: null,
          representativeTribeId: null,
          mccMnc: null,
        },
        fcmToken: null,
        showPinCode: true,
        redirectUri: createUrlWithHost(urls.registeredUserRedirect),
      },
      apiKey: config.emailLoginApiKey,
    }
    magicLinkApi
      .query(requestData)
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              switch (error.type) {
                case 'FETCH_ERROR':
                case 'API_ERROR':
                  return onError(error, error.type)
                case 'ABORT_ERROR':
                case 'THROTTLE_ERROR':
                case 'WRONG_PIN_CODE':
                  return
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return onError(new Error('email magiclink login authentication renewal error'))
                case 'AUTHENTICATION_ERROR':
                  return onError(new Error('email magiclink login authentication error'))
                case 'NO_ACCOUNT':
                  return history.push(urls.createAccountWithEmail.clientUrl(email))
                default:
                  return exhaustiveCheck(error)
              }
            },
            (data) => history.push(urls.enterEmailLoginPinCode.clientUrl(email, data.code))
          )
        )
      )
      .catch(onError)
  }

  const emailIsInvalid = !validateEmail(email)

  return (
    <EmailContainer onSubmit={onEmailLogin}>
      <label
        css={`
          font-size: 0.875rem;
          font-weight: 400;
          color: ${colors.HMDBlue};
        `}
      >
        {t('emailLogin.helpText')}
        {config.contentfulPreview ? '.' : '..'}
        <InputContainer>
          <div
            css={`
              line-height: 2.125rem;
            `}
          >
            <MailIcon
              color={'#7F8E96'}
              css={`
                height: 2.125rem;
                width: 2.5rem;
                margin: 0.375rem 0.75rem 0.5rem 0.75rem;
                padding: 0.1875rem 0.5rem 0.1875rem 0;
                border-right: 2px solid #c2cddb;
                vertical-align: middle;
              `}
            />
          </div>
          <EmailInput
            id={EMAIL_LOGIN_INPUT_ID}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder={t('emailLogin.placeholder')}
            name="email"
          />
        </InputContainer>
      </label>
      <div
        css={`
          text-align: center;
        `}
      >
        <Button
          css={`
            width: 100%;
          `}
          id={EMAIL_LOGIN_BUTTON_ID}
          type={'submit'}
          loading={magicLinkApi.isLoading}
          disabled={magicLinkApi.isLoading || emailIsInvalid}
          text={
            <div
              css={`
                display: flex;
                align-items: center;
              `}
            >
              {t('emailLogin.continue')}
              <LeftArrowIcon
                css={`
                  margin-left: 0.625rem;
                  transform: rotate(180deg);
                `}
                color={'#fff'}
              />
            </div>
          }
          onSubmit={onEmailLogin}
        />
      </div>
    </EmailContainer>
  )
}
