import React from 'react'
import { IconWithColor } from '../types'

export const HomeIcon = (props: IconWithColor) => (
  <svg className={props.className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="icons/_glyphs/home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon fill={props.color} points="10 20 10 14 14 14 14 20 19 20 19 12 22 12 12 3 2 12 5 12 5 20" />
    </g>
  </svg>
)
