import { pipe } from 'fp-ts/es6/pipeable'
import * as t from 'io-ts'
import { fold } from 'fp-ts/es6/Either'
import { GetCountryListSuccessResponse, Country, FormattedLocalisations } from './api/api-types'

const getStorageApi = (storageType: 'localStorage' | 'sessionStorage') => {
  try {
    const storage = window[storageType]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return storage
  } catch (e) {
    return null
  }
}

const storagePrefix = 'hmd-hermes-'
export const jwtKey = `${storagePrefix}jwt`
const usedLanguage = `${storagePrefix}language`
const jwtRefreshKey = `${storagePrefix}jwt-refresh-token`
const countryListKey = (lang: string) => `${storagePrefix}countryList-${lang}`
const localisationsKey = (lang: string) => `${storagePrefix}localisations-${lang}`
const updateAvailableKey = `${storagePrefix}update-available`

export const persistNewVersionAvailable = () => {
  const storage = getStorageApi('sessionStorage')
  if (!storage) {
    return
  }

  storage.setItem(updateAvailableKey, 'available')
}

export const getNewVersionAvailable = () => {
  const storage = getStorageApi('sessionStorage')
  if (!storage) {
    return undefined
  }

  const value = storage.getItem(updateAvailableKey)
  storage.removeItem(updateAvailableKey)
  return typeof value === 'string'
}

export const persistJwtToken = (jwtToken: string | null) => {
  const storage = getStorageApi('sessionStorage')
  if (!storage) {
    return
  }

  if (jwtToken) {
    return storage.setItem(jwtKey, jwtToken)
  } else {
    return clearCachedJwtToken()
  }
}

export const persistJwtRefreshToken = (jwtRefreshToken: string | null) => {
  const storage = getStorageApi('sessionStorage')
  if (!storage) {
    return
  }

  if (jwtRefreshToken) {
    return storage.setItem(jwtRefreshKey, jwtRefreshToken)
  } else {
    return clearCachedJwtRefreshToken()
  }
}

export const getCachedJwtToken = () => {
  const storage = getStorageApi('sessionStorage')
  if (!storage) {
    return null
  }

  const token = storage.getItem(jwtKey)

  if (token !== null) {
    return token
  }

  return null
}

export const getCachedJwtRefreshToken = () => {
  const storage = getStorageApi('sessionStorage')
  if (!storage) {
    return null
  }

  return storage.getItem(jwtRefreshKey)
}

export const clearCachedJwtToken = () => {
  const storage = getStorageApi('sessionStorage')
  if (!storage) {
    return
  }
  storage.removeItem(jwtKey)
}

export const clearCachedJwtRefreshToken = () => {
  const storage = getStorageApi('sessionStorage')
  if (!storage) {
    return
  }
  storage.removeItem(jwtRefreshKey)
}

export const getCachedCountryList = (language: string): GetCountryListSuccessResponse | null => {
  const storage = getStorageApi('localStorage')
  if (!storage) {
    return null
  }

  const CountryListData = t.type({
    countries: t.array(Country),
    timestamp: t.number,
  })
  const maybeCountries = storage.getItem(countryListKey(language))
  const json = maybeCountries ? JSON.parse(maybeCountries) : null
  return pipe(
    CountryListData.decode(json),
    fold(
      (_error) => null,
      (data) => {
        const age = Date.now() - data.timestamp
        const ageLimit = 24 * 60 * 60 * 1000

        // reuse cached data for up to 14 days
        if (age < ageLimit) {
          return data.countries
        }
        return null
      }
    )
  )
}

export const setCachedCountryList = (language: string, countries: GetCountryListSuccessResponse) => {
  const storage = getStorageApi('localStorage')
  if (!storage) {
    return
  }
  storage.setItem(countryListKey(language), JSON.stringify({ countries, timestamp: Date.now() }))
}

export const getCachedLocalisations = (lang: string): FormattedLocalisations | null => {
  const storage = getStorageApi('localStorage')
  if (!storage) {
    return null
  }

  const LocalisationsData = t.type({
    localisations: FormattedLocalisations,
    timestamp: t.number,
  })
  const maybeLocalisations = storage.getItem(localisationsKey(lang))
  const json = maybeLocalisations ? JSON.parse(maybeLocalisations) : null

  return pipe(
    LocalisationsData.decode(json),
    fold(
      (_error) => null,
      (data) => {
        const age = Date.now() - data.timestamp
        const ageLimit = 24 * 60 * 60 * 1000

        // reuse cached data for up to 24 hours
        if (age < ageLimit) {
          return data.localisations
        }
        return null
      }
    )
  )
}

export const setCachedLocalisations = (localisations: FormattedLocalisations, lang: string) => {
  const storage = getStorageApi('localStorage')
  if (!storage) {
    return
  }
  storage.setItem(localisationsKey(lang), JSON.stringify({ localisations, timestamp: Date.now() }))
}

export const getUsedLanguage = (): string | null => {
  const storage = getStorageApi('localStorage')
  if (!storage) {
    return null
  }

  return storage.getItem(usedLanguage)
}

export const setUsedLanguage = (lang: string) => {
  const storage = getStorageApi('localStorage')
  if (!storage) {
    return
  }
  storage.setItem(usedLanguage, lang)
}
