import React from 'react'
import { Icon } from '../types'

export const GoogleIcon = (props: Icon) => (
  <svg className={props.className} width={'24px'} height={'24px'} viewBox="0 0 24 24">
    <title>Google</title>
    <path
      fill="#001E2D"
      d="M12 13.71v-3.35h8.43c.12.57.22 1.1.22 1.85 0 5.14-3.45 8.79-8.64 8.79a9 9 0 010-18c2.43 0 4.46.9 6.02 2.35l-2.56 2.48a4.95 4.95 0 00-3.46-1.33 5.47 5.47 0 00-5.4 5.5c0 3.04 2.42 5.52 5.4 5.52 3.45 0 4.72-2.39 4.95-3.8H12v-.01z"
    />
  </svg>
)
