import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { config } from '../../config'
import { formatPageTitle } from '../../formatPageTitle'
import { AppleIcon } from '../../icons/AppleIcon'
import { FacebookIcon } from '../../icons/FacebookIcon'
import { GoogleIcon } from '../../icons/GoogleIcon'
import { APPLE_SIGN_IN_ID, FACEBOOK_SIGN_IN_ID, GOOGLE_SIGN_IN_ID } from '../../test-selectors'
import { colors } from '../../theme'
import { urls } from '../../urls'
import { createUrlWithHost } from '../../utils/url-utils'
import { ExternalLink } from '../common/ExternalLink'
import { Loading } from '../Loading/Loading'
import { BuySimLink } from './BuySimLink'
import { EmailLogin } from './EmailLogin'
import { SignInWrapper } from './SignInWrapper'

const createSignInUrl = (type: 'Facebook' | 'Google' | 'Apple') =>
  `${process.env.AUTH_API_BASE_URL}/auth/signin/${type}?redirectUri=${createUrlWithHost(
    urls.registeredUserRedirect
  )}&regUrl=${createUrlWithHost(urls.createAccountWithSome.clientUrl())}&program=Hermes`

const LoginLink = styled.a`
  transition: 0.3s;
  padding: 0 1.25rem;
  border: 1px solid #c2cddb;
  border-radius: 0.5rem;
  color: ${colors.deepBlueDim};
  background-color: #fff;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: normal;
  svg {
    margin-left: auto;
  }
  &:hover {
    opacity: 0.7;
  }
`

const ButtonContainer = styled.div`
  margin: 1.25rem 0;
`

const BottomTerms = styled.div`
  text-align: center;
  color: #acabac;
  font-size: 0.875rem;
`

const BottomTermsLink = styled(ExternalLink)`
  color: ${colors.hermesGreen};
  text-decoration: none;
`

const Title = styled.div`
  color: #000;
  font-size: 3rem;
  line-height: 110%;
  font-weight: 500;
`

interface Props {
  showEmailField: boolean
}

export const SignIn = ({ showEmailField }: Props) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const onClick = () => setIsLoading(true)

  useEffect(() => {
    document.title = formatPageTitle('Sign in')
  }, [])

  return (
    <SignInWrapper>
      <>
        <Title>{t('signinPage.title')}</Title>
        {isLoading ? (
          <Loading
            css={`
              margin-top: 1.25rem;
            `}
            color={colors.brightBlue}
          />
        ) : (
          <>
            {config.showNonProductionEnvWarning && (
              <div
                css={`
                  margin: 1.25rem 0;
                  text-align: center;
                `}
              >
                <div
                  css={`
                    @keyframes blinker {
                      50% {
                        opacity: 0;
                      }
                    }
                    font-size: 1.75rem;
                    animation: blinker 1s linear infinite;
                    color: ${colors.warningRed};
                  `}
                >
                  YOU ARE USING TEST ENVIRONMENT
                </div>
                <p>
                  Test environment is not linked to the actual SIM cards. This environment also has some extra test
                  buttons which are not visible in the production environment.
                </p>
                <ExternalLink
                  css={`
                    color: #000;
                    margin-top: 0.3125rem;
                  `}
                  href={'https://prod-hermes.azureedge.net/'}
                >
                  <>Click here for the Production environment</>
                </ExternalLink>
              </div>
            )}
            <div
              css={`
                font-size: 1rem;
                line-height: 1.5rem;
                color: ${colors.HMDBlue}
                margin-top: 1.25rem;
              `}
            >
              {'Continue to activate your first SIM card or to sign in to your account.'}
            </div>
            <ButtonContainer>
              <LoginLink
                id={GOOGLE_SIGN_IN_ID}
                onClick={onClick}
                href={createSignInUrl('Google')}
                data-gtm-cta={'Sign-in-google'}
              >
                {t('signinPage.loginButtons.google')} <GoogleIcon />
              </LoginLink>
              <LoginLink
                id={APPLE_SIGN_IN_ID}
                onClick={onClick}
                href={createSignInUrl('Apple')}
                data-gtm-cta={'Sign-in-apple'}
              >
                {t('signinPage.loginButtons.apple')} <AppleIcon />
              </LoginLink>
              <LoginLink
                id={FACEBOOK_SIGN_IN_ID}
                onClick={onClick}
                href={createSignInUrl('Facebook')}
                data-gtm-cta={'Sign-in-facebook'}
              >
                {t('signinPage.loginButtons.facebook')} <FacebookIcon />
              </LoginLink>
              {showEmailField && <EmailLogin />}
            </ButtonContainer>

            <BottomTerms>
              <div>
                {`By continuing, you agree to HMD Global's `}
                <BottomTermsLink href={config.serviceTermsUrl}>
                  <>Service Terms</>
                </BottomTermsLink>
                ,{' '}
                <BottomTermsLink href={config.privacyPolicyUrl}>
                  <>Privacy Policy</>
                </BottomTermsLink>{' '}
                and HMD Mobile{' '}
                <BottomTermsLink href={config.privacySupplementUrl}>
                  <>Privacy Supplement</>
                </BottomTermsLink>
              </div>
            </BottomTerms>

            <BuySimLink />
          </>
        )}
      </>
    </SignInWrapper>
  )
}
