import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { fold } from 'fp-ts/es6/Either'
import frompairs from 'lodash.frompairs'
import { componentTestEnv } from '../__tests__/test-utils'
import { getLocalisations } from './api/api-contentful'
import { ContentfulLocalisationResponse, UnsupportedResponse, FormattedLocalisations } from './api/api-types'
import { getCachedLocalisations, setCachedLocalisations, getUsedLanguage, setUsedLanguage } from './storage-api'
import { config } from './config'

const resources = {}
const LANGUAGES: { name: string; code: string }[] = [
  {
    name: 'English',
    code: 'en',
  },
]

const DEFAULT_LANG = LANGUAGES[0].code

const getNavigatorLanguage = (): string | null => {
  const langsWithoutRegion = window.navigator.languages.filter((code: string) => !code.includes('-'))
  return langsWithoutRegion ? langsWithoutRegion[0] : null
}

const getLogicalLanguage = (): string | null => {
  const navigatorLanguage = getNavigatorLanguage()
  return LANGUAGES.find((lang: { name: string; code: string }) => lang.code === navigatorLanguage)?.code || null
}

const getLanguageFromQueryString = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search)
  const langParam = urlParams.get('lang')
  return langParam
}

const savedLanguage = getUsedLanguage()
const lng = getLanguageFromQueryString() || savedLanguage || getLogicalLanguage() || DEFAULT_LANG

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources,
    lng,

    keySeparator: '', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      bindI18n: 'loaded',
      bindI18nStore: 'added',
    },
  })

const unescapeInterpolationCharacters = (formattedKeyValuePairs: [string, string][]): [string, string][] => {
  const unescapeString = (string: string): string => string.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
  return formattedKeyValuePairs.map(([key, value]) => [key, unescapeString(value)])
}

const parseKeyValuePairsToObject = ({ data }: ContentfulLocalisationResponse): FormattedLocalisations => {
  const keyValuePairs = data.appContentCollection.items[0].keyValuePairsCollection.items
  const formattedKeyValuePairs: [string, string][] = keyValuePairs.map((keyValuePair) => [
    keyValuePair.key,
    keyValuePair.value.string,
  ])
  return frompairs(unescapeInterpolationCharacters(formattedKeyValuePairs))
}

const onContentfulResponse = (lang: string) => (response: ContentfulLocalisationResponse): void => {
  const localisationObject = parseKeyValuePairsToObject(response)
  setCachedLocalisations(localisationObject, lang)
  addFormattedLocalisationsToI18Next(lang, localisationObject)
}

const addFormattedLocalisationsToI18Next = (lang: string, localisations: FormattedLocalisations): void => {
  i18n.addResourceBundle(lang, 'translation', localisations, true)
}

const onContentfulError = (_error: UnsupportedResponse) => {
  throw new Error('Problem occurred while retrieiving localization data.')
}

const initContentfulLocalisations = (lang: string): void => {
  const useCache = !config.contentfulPreview
  const cachedLocalisations = useCache ? getCachedLocalisations(lang) : null
  if (cachedLocalisations) {
    addFormattedLocalisationsToI18Next(lang, cachedLocalisations)
    return
  }
  void getLocalisations(lang).then(fold(onContentfulError, onContentfulResponse(lang)))
}

const init = (): void => {
  if (componentTestEnv) {
    return
  }

  if (i18n.isInitialized) {
    initContentfulLocalisations(lng)
  } else {
    i18n.on('initialized', (_options) => {
      initContentfulLocalisations(lng)
    })
  }

  i18n.on('languageChanged', (lng) => {
    if (savedLanguage !== lng) {
      setUsedLanguage(lng)
    }
    initContentfulLocalisations(lng)
  })
}

init()

export { i18n, LANGUAGES }
