import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { colors } from '../../theme'

const Container = styled.div``

const Label = styled.label<{ checked: boolean }>`
  position: relative;
  cursor: pointer;
  width: 1.375rem;
  height: 1.375rem;
  display: block;
  user-select: none;
  border-width: 0.125rem;
  border-color: ${(props) => (props.checked ? colors.hermesGreen : '#DEDFDF')};
  border-style: solid;
  border-radius: 4px;
`

const Input = styled.input`
  appearance: none;
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
`

const CheckMark = styled.div<{ checked: boolean }>`
  transition: opacity 0.2s;
  opacity: ${(props) => (props.checked ? 1 : 0)};
  background-color: ${(props) => (props.checked ? colors.hermesGreen : '#fff')};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.hermesGreen};
`

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Checkbox = (props: Props) => {
  const [checked, setChecked] = useState<boolean>(!!props.checked)
  const { ref, onChange, className, ...rest } = props

  useEffect(() => {
    if (typeof props.checked === 'boolean') {
      setChecked(props.checked)
    }
  }, [props.checked])

  return (
    <Container className={className}>
      <Label checked={checked}>
        <CheckMark checked={checked}>
          <div
            css={`
              content: '';
              margin-bottom: 0.125rem;
              width: 0.375rem;
              height: 0.875rem;
              border: solid #fff;
              border-width: 0 0.125rem 0.125rem 0;
              transform: rotate(45deg);
            `}
          />
        </CheckMark>
        <Input
          {...rest}
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked)
            if (onChange) {
              onChange(e)
            }
          }}
        />
      </Label>
    </Container>
  )
}
