import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { selectActiveAccount } from '../../api/api'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../../api/api-utils'
import { useAppQueryContext } from '../../context/AppQueryContext'
import { useErrorContext } from '../../context/ErrorContext'
import { JwtContext } from '../../context/JwtContext'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import { AccountIcon } from '../../icons/AccountIcon'
import { DownArrow } from '../../icons/DownArrow'
import { HomeIcon } from '../../icons/HomeIcon'
import { LifePreserverIcon } from '../../icons/LifePreserver'
import { LogoIcon } from '../../icons/LogoIcon'
import { MENU_ACCOUNT_ID, MENU_BUTTON_ID, MENU_HOME_ID } from '../../test-selectors'
import { colors, containerWidths } from '../../theme'
import { urls } from '../../urls'
import { formatPhonenumber } from '../../utils/text-utils'
import { Loading } from '../Loading/Loading'
import { MenuBuySimLink } from './MenuBuySimLink'
import { MenuIcon } from './MenuIcon'

const Logo = styled(LogoIcon)``

export const desktopMenuWidth = '19rem'
const mobileMenuSize = '3.4375rem'

const MenuItemText = styled.div`
  padding-left: 0.625rem;
`

const MenuContent = styled.nav<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #fff;
  position: relative;
  width: 100%;
  ${Logo} {
    display: none;
  }

  @media screen and (min-width: ${containerWidths.lg}px) {
    padding: 5.625rem 0 0 0;
    display: flex;
    top: 0;
    position: fixed;
    height: 100%;
    width: ${desktopMenuWidth};
    ${Logo} {
      display: block;
    }
  }
`

const MenuButton = styled.div`
  -webkit-tap-highlight-color: transparent;
  position: sticky;
  top: 0;
  color: ${colors.hermesGreen};
  cursor: pointer;
  width: ${mobileMenuSize};
  height: ${mobileMenuSize};
  padding: 0.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  outline: none;
  margin-right: 0.625rem;
  &:hover {
    opacity: 0.7;
  }

  svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: ${containerWidths.lg}px) {
    display: none;
  }
`

const DDContainer = styled.div`
  background: ${colors.deepBlue};
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
`

const DDMenu = styled.div<{ active: boolean }>`
  opacity: ${(props) => (props.active ? 1 : 0)};
  display: ${(props) => (props.active ? 'inline' : 'none')};
  transition: opacity 0.4s ease, display 0.4s;
`

const DDMenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const DDMenuListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 24px;
  margin-top: 24px;
`

const DDMenuListItemLink = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  opacity: 0.7;
  color: ${colors.HMDBlue};
  &:hover {
    opacity: 1;
  }
`

const DDButton = styled.div<{ active: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* border-bottom: ${(props) => (props.active ? '1px solid #00939A' : 'none')}; */

  &:hover {
    opacity: 0.7;
  }
`

const menuInactiveColor = '#71828a'
const menuActiveColor = colors.hermesGreen
const MenuItem = styled.div`
  margin: 0.625rem 0;
  color: ${menuInactiveColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3125rem;
    * {
      fill: ${menuInactiveColor};
    }
  }

  &:hover {
    opacity: 0.7;
  }
`

const MenuLinkStyle = css`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${menuInactiveColor};

  padding: 0.3125rem 0 0.3125rem 0.625rem;
  @media screen and (min-width: ${containerWidths.lg}px) {
    padding: 0.625rem 0 0.625rem 1.25rem;
  }

  &.active {
    color: ${menuActiveColor};
    border-left: 0.25rem solid ${menuActiveColor};
    svg {
      * {
        fill: ${menuActiveColor};
      }
    }

    padding: 0.3125rem 0 0.3125rem 0.375rem;
    @media screen and (min-width: ${containerWidths.lg}px) {
      padding: 0.625rem 0 0.625rem 1.625rem;
    }
  }
`

const MenuLink = styled(NavLink)`
  ${MenuLinkStyle};
`

export const Menu = () => {
  const { t } = useTranslation()
  const [menuIsVisible, setMenuIsVisible] = useState(false)

  const routesForCompleteUsers = [
    <MenuItem key="home">
      <MenuLink id={MENU_HOME_ID} to={urls.home}>
        <HomeIcon color={'#000'} />
        <MenuItemText>{t('menu.home')}</MenuItemText>
      </MenuLink>
    </MenuItem>,

    <MenuItem key="account">
      <MenuLink id={MENU_ACCOUNT_ID} to={urls.account}>
        <AccountIcon color={'#000'} />
        <MenuItemText>{t('menu.account')}</MenuItemText>
      </MenuLink>
    </MenuItem>,

    <MenuItem key="support">
      <MenuLink to={urls.support.index.clientUrl()}>
        <LifePreserverIcon color={'#000'} />
        <MenuItemText>{t('menu.support')}</MenuItemText>
      </MenuLink>
    </MenuItem>,
    <MenuBuySimLink key={'buy-sim'} />,
  ]

  const { appQuery, dispatch } = useAppQueryContext()
  const jwtContext = React.useContext(JwtContext)
  const { onError } = useErrorContext()
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useState(false)
  const onClick = () => setIsActive(!isActive)
  const api = useDataLoader(selectActiveAccount)
  const { query, isLoading } = api

  return (
    <nav
      css={`
        position: sticky;
        top: 0;
        z-index: 9999;
        background: #fff;

        @media screen and (min-width: ${containerWidths.lg}px) {
          width: ${desktopMenuWidth};
        }
      `}
    >
      <div
        css={`
          display: flex;
          align-items: center;
          height: ${mobileMenuSize};
        `}
      >
        <MenuButton id={MENU_BUTTON_ID} onClick={() => setMenuIsVisible(!menuIsVisible)}>
          <MenuIcon color={colors.hermesGreen} />
        </MenuButton>
        <Link
          css={`
            display: flex;
            text-decoration: none;
          `}
          to={urls.home}
        >
          <LogoIcon
            css={`
              width: 6.875rem;
              height: 100%;
              display: inline-block;
              @media screen and (min-width: ${containerWidths.lg}px) {
                display: none;
              }
            `}
          />
        </Link>
      </div>

      <MenuContent isVisible={menuIsVisible}>
        <div
          css={`
            border-bottom: 1px dashed #c2cddb;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 5.625rem;

            display: none;
            @media screen and (min-width: ${containerWidths.lg}px) {
              display: flex;
            }
          `}
        >
          <Link
            css={`
              display: flex;
              text-decoration: none;
              margin: 1.5rem;
            `}
            to={urls.home}
          >
            <LogoIcon
              css={`
                height: 1.5625rem;
                width: 8.75rem;
              `}
            />
          </Link>
        </div>
        {appQuery?.subscription !== null &&
          appQuery?.alternateAccounts !== null &&
          (appQuery?.alternateAccounts?.length ?? 0) > 1 && (
            <DDContainer>
              <a onClick={onClick}>
                <DDButton active={isActive}>
                  <label
                    css={`
                      font-style: normal;
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 20px;
                      letter-spacing: 0.5px;
                      color: ${colors.brightBlue};
                    `}
                  >
                    {formatPhonenumber(appQuery?.subscription?.msisdn ?? '')}
                  </label>
                  {isLoading ? (
                    <Loading
                      color={colors.brightBlue}
                      size={20}
                      margin={0}
                      css={`
                        margin-right: 0.3125rem;
                        display: flex;
                      `}
                    />
                  ) : (
                    <DownArrow fliped={isActive} color={colors.brightBlue} />
                  )}
                </DDButton>
              </a>
              <DDMenu active={isActive} ref={dropdownRef}>
                <DDMenuList>
                  {appQuery?.alternateAccounts?.map((alternateAccount: any) => (
                    <DDMenuListItem key={alternateAccount.uid}>
                      <DDMenuListItemLink
                        onClick={() => {
                          setIsActive(false)
                          query({
                            jwtToken: jwtContext?.jwtToken ?? '',
                            body: {
                              msisdn: alternateAccount.msisdn,
                              uid: alternateAccount.uid,
                            },
                          })
                            .then((response) =>
                              pipe(
                                response,
                                fold(
                                  (error) => {
                                    switch (error.type) {
                                      case 'THROTTLE_ERROR':
                                      case 'WRONG_PIN_CODE':
                                        return
                                      case 'FETCH_ERROR':
                                      case 'API_ERROR':
                                      case 'UNSUPPORTED_RESPONSE':
                                        return onError(error, error.type)
                                      case 'ABORT_ERROR':
                                        return
                                      case 'AUTHENTICATION_EXPIRED_ERROR':
                                        return renewJwtTokenOnAuthenticationExpiredError()
                                      case 'AUTHENTICATION_ERROR':
                                        return redirectToSignInOnAuthenticationError()
                                      default:
                                        return exhaustiveCheck(error)
                                    }
                                  },
                                  (appQueryData) => {
                                    switch (appQueryData.type) {
                                      case 'full-response':
                                        dispatch({ type: 'select-account', appQuery: appQueryData.appQuery })
                                    }
                                  }
                                )
                              )
                            )
                            .catch(onError)
                        }}
                      >
                        {formatPhonenumber(alternateAccount.msisdn)}
                      </DDMenuListItemLink>
                    </DDMenuListItem>
                  ))}
                </DDMenuList>
              </DDMenu>
            </DDContainer>
          )}
        {routesForCompleteUsers}
      </MenuContent>
    </nav>
  )
}
