import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from '../../theme'

const TopFeedback = styled.div<{ isVisible: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  overflow: hidden;
  transform-origin: top;
  transform: scaleY(${(props) => (props.isVisible ? 1 : 0)});
  min-height: ${(props) => (props.isVisible ? '3.75rem' : 0)};
  height: ${(props) => (props.isVisible ? 'auto' : 0)};
  z-index: 99999;
  position: sticky;
`

const CloseButton = styled.div`
  margin-right: 0.625rem;
  padding: 0.3125rem;
  top: 0;
  font-size: 0.875rem;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

const Text = styled.div`
  padding: 0.625rem;
  flex: 1;
`

interface Props {
  onClose?: () => void
  message?: JSX.Element
  className?: string
}

export const Toast = ({ onClose, message, className }: Props) => {
  const { t } = useTranslation()
  return (
    <TopFeedback className={className} isVisible={!!message}>
      <Text>{message}</Text>
      {onClose && <CloseButton onClick={() => onClose()}>{t('toast.dismiss')}</CloseButton>}
    </TopFeedback>
  )
}

export const SuccessToast = styled(Toast)`
  background-color: ${colors.hermesGreen};
  color: #fff;
  border-bottom: 0.0625rem solid #498680;
`

export const WarningToast = styled(Toast)`
  background-color: ${colors.warningRed};
  color: #fff;
  border-bottom: 0.0625rem solid #e64040;
`
