import React from 'react'
import { IconWithColor } from '../types'

export const DataPlansIcon = (props: IconWithColor) => (
  <svg className={props.className} width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
    <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="navigation/bottom-navigation/inactive-item" fillRule="evenodd">
        <g id="icons/icon-24px">
          <g id="icons/_glyphs/data-color">
            <polygon
              id="data-plans-bg-color"
              fill="transparent"
              points="5 22 19 22 19 2 5 2"
              css={`
                fill: transparent !important;
              `}
            />
            <path
              d="M15,15.0098 L15,9.9998 L13,9.9998 L13,15.0098 L11,15.0098 L14,18.9998 L17,15.0098 L15,15.0098 Z M10,4.9998 L7,8.9898 L9,8.9898 L9,13.9998 L11,13.9998 L11,8.9898 L13,8.9898 L10,4.9998 Z M6,20.9998 L18,20.9998 L18,2.9998 L6,2.9998 L6,20.9998 Z M18,-0.0002 L6,-0.0002 C4.9,-0.0002 4,0.8998 4,1.9998 L4,21.9998 C4,23.0998 4.9,23.9998 6,23.9998 L18,23.9998 C19.1,23.9998 20,23.0998 20,21.9998 L20,1.9998 C20,0.8998 19.1,-0.0002 18,-0.0002 L18,-0.0002 Z"
              fill={props.color}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
