import React from 'react'
import { IconWithColor } from '../types'

export const LifePreserverIcon = (props: IconWithColor) => (
  <svg className={props.className} version="1.1" width="24px" height="24px" viewBox="0 0 16 16">
    <path d="M8,0C5.971,0,4.124,0.762,2.714,2.006l2.845,2.845C6.236,4.326,7.076,4,8,4s1.764,0.326,2.441,0.852l2.845-2.845  C11.876,0.762,10.029,0,8,0z" />
    <path d="M5.559,11.148l-2.845,2.845C4.124,15.238,5.971,16,8,16s3.876-0.762,5.286-2.006l-2.845-2.845C9.764,11.674,8.924,12,8,12  S6.236,11.674,5.559,11.148z" />
    <path d="M11.148,5.559C11.674,6.236,12,7.076,12,8c0,0.924-0.326,1.764-0.852,2.441l2.845,2.845C15.238,11.876,16,10.029,16,8  s-0.762-3.876-2.006-5.286L11.148,5.559z" />
    <path d="M4.852,10.441C4.326,9.764,4,8.924,4,8c0-0.924,0.326-1.764,0.852-2.441L2.006,2.714C0.762,4.124,0,5.971,0,8  s0.762,3.876,2.006,5.286L4.852,10.441z" />
  </svg>
)
