import { useLocation, Switch, Route, Redirect } from 'react-router'
import React from 'react'
import * as queryString from 'query-string'
import { urls } from './urls'
import { ActivatePlanRoute } from './ActivatePlan/ActivatePlanRoute'
import { SoMeCreateAccountRoute } from './components/CreateAccount/SoMeCreateAccountRoute'
import { RegisteredUserRedirect } from './components/RegisteredUserRedirect'
import { PageNotFound } from './components/PageNotFound'
import { AppQueryLoader } from './AppQueryLoader'
import { SignIn } from './components/SignIn/SignIn'
import { EmailCreateAccountRoute } from './components/CreateAccount/EmailCreateAccountRoute'
import { config } from './config'
import { useCountryListLoader } from './customHooks/useCountryListLoader'
import { EmailPinCodeLoginRoute } from './components/SignIn/EmailPinCodeLoginRoute'
import { ActivatePlanManuallyRoute } from './ActivatePlan/ActivatePlanManuallyRoute'

interface Props {
  jwtToken: string | null
  jwtRefreshToken: string | null
}

const redirectToSignIn = <Redirect to={urls.signIn} />

export const Routes = ({ jwtToken }: Props) => {
  const location = useLocation()

  // refresh & cache country data
  useCountryListLoader()

  const { activationId } = queryString.parse(location.search)

  return (
    <Switch>
      <Route exact={true} path={urls.signIn}>
        {jwtToken ? <Redirect to={urls.home} /> : <SignIn showEmailField={!!config.emailLoginApiKey} />}
      </Route>

      <Route path={urls.activateManually}>
        {jwtToken ? <ActivatePlanManuallyRoute jwtToken={jwtToken} /> : redirectToSignIn}
      </Route>

      <Route path={urls.activatePlan.url}>
        {jwtToken && typeof activationId === 'string' ? (
          <ActivatePlanRoute jwtToken={jwtToken} activationId={activationId} />
        ) : (
          redirectToSignIn
        )}
      </Route>

      <Route path={urls.authenticatedUserBaseUrl}>
        {jwtToken ? <AppQueryLoader jwtToken={jwtToken} /> : redirectToSignIn}
      </Route>

      <Route path={urls.createAccountWithSome.url}>
        <SoMeCreateAccountRoute />
      </Route>

      <Route path={urls.createAccountWithEmail.url}>
        <EmailCreateAccountRoute />
      </Route>

      <Route path={urls.registeredUserRedirect}>
        <RegisteredUserRedirect />
      </Route>

      <Route path={urls.enterEmailLoginPinCode.url}>
        <EmailPinCodeLoginRoute />
      </Route>

      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  )
}
