import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { colors, containerWidths } from '../../theme'
import { VALIDATION_ERROR_CLASSNAME } from '../../test-selectors'
import { defaultBorderRadius } from '../styles/defaultBorderRadius'
import { PageContainer } from './Page'

export const AlignCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`

export const SectionContainer = styled.div`
  border-radius: ${defaultBorderRadius};
  background-color: #fff;
  padding: 0.625rem;
  @media screen and (min-width: ${containerWidths.modernPhone}px) {
    padding: 0.9375rem;
  }
  @media screen and (min-width: ${containerWidths.md}px) {
    padding: 1.25rem;
  }
`

export const ValidationError = styled.div.attrs({
  className: VALIDATION_ERROR_CLASSNAME,
  'data-testid': VALIDATION_ERROR_CLASSNAME,
})`
  font-size: 1rem;
  color: ${colors.warningRed};
`

export const TextInputBase = styled.input<{ isInvalid?: boolean }>`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  outline: none;
  line-height: 1.625rem;
  height: 3.5rem;
  transition: border-color 0.2s linear;
  padding: 0.875rem;
  background-color: #fff;
  color: ${colors.HMDBlue};
  font-size: 1rem;
  font-weight: 500;
  border-radius: ${defaultBorderRadius};
  border-style: solid;
  border-width: 0.125rem;
  border-color: ${(props) => (props.isInvalid ? colors.errorRed : colors.newBorderColor)};
  &:focus {
    outline: none;
    border-color: ${colors.darkGrey};
  }
  &:disabled {
    opacity: 0.6;
  }
  ::placeholder {
    color: rgba(0, 30, 45, 0.3);
  }
`

export const BottomBar = css`
  position: sticky;
  margin-top: 1.25rem;
  left: 0;
  bottom: 0.3125rem;
  width: 100%;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${defaultBorderRadius};
  &:hover {
    opacity: 1;
    box-shadow: 0 0 0.6875rem rgba(33, 33, 33, 0.2);
  }
`

export const BottomLinkBar = styled(Link)`
  ${BottomBar};
  background-color: ${colors.hermesGreen};
  color: #fff;
  text-decoration: none;
  border: 0.0625rem solid #54a7af;
`

export const BottomLinkBarTransparent = styled(Link)`
  ${BottomBar};
  color: ${colors.hermesGreen};
  background-color: ${colors.bgColor};
  text-decoration: none;
  border: 0.125rem dashed ${colors.lightGrey};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25rem;
`

export const ResponsivePadding = css`
  padding: 0.625rem;
  @media screen and (min-width: ${containerWidths.md}px) {
    padding: 1.875rem;
  }
  @media screen and (min-width: ${containerWidths.lg}px) {
    padding: 3.125rem;
  }
`

export const DialogWrapper = styled.div<{ hidePage: boolean }>`
  ${PageContainer} {
    display: ${(props) => (props.hidePage ? 'none !important' : 'flex')};
  }
`

export const SectionTitle = styled.h3`
  color: ${colors.greyText};
  margin: 0.75rem 0;
  font-weight: 500;
`
