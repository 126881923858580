import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import { SignInWrapper } from '../components/SignIn/SignInWrapper'
import { formatPageTitle } from '../formatPageTitle'
import { GreenButton } from '../components/Button/Button'
import { colors, containerWidths } from '../theme'
import { postRegisterIcc } from '../api/api'
import { useDataLoader } from '../customHooks/useDataLoader'
import { JwtTokenProps } from '../types'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../api/api-utils'
import { exhaustiveCheck } from '../exhaustive-switch-check'
import { useErrorContext } from '../context/ErrorContext'
import { useAppQueryContext } from '../context/AppQueryContext'
import { TruckIcon } from '../icons/TruckIcon'
import IccInstructions from '../images/icc-instructions.png'
import { Picture } from '../components/common/Picture'
import { urls } from '../urls'
import { StyledBaseButton } from '../components/Button/StyledBaseButton'
import { LeftArrowIcon } from '../icons/LeftArrowIcon'
import { logOut } from '../utils/utils'

const Content = styled.p`
  margin: 0;
  padding: 1rem 2rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(0, 30, 45, 0.6);
  > b {
    font-weight: 700;
    color: ${colors.hermesGreen};
  }
`

const Label = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.3125rem;
`

const Textarea = styled.input`
  margin-top: 0.5rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  width: 100%;
  outline: none;
  margin-bottom: 0.625rem;
  border: 0.0625rem solid ${colors.borderColor};
`

export const BackButton = ({ onClick }: { onClick: () => void }) => (
  <StyledBaseButton
    css={`
      margin-bottom: 3rem;
      display: flex;
      justify-content: start;
      width: 100%;
    `}
    onClick={onClick}
  >
    <LeftArrowIcon
      color={colors.hermesGreen}
      css={`
        margin-right: 0.625rem;
      `}
    />
  </StyledBaseButton>
)

const ImageContainer = ({ image }: { image: string }) => (
  <div
    css={`
      margin: auto;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      @media screen and (min-width: ${containerWidths.md}px) {
        width: 80%;
      }
    `}
  >
    <Picture
      css={`
        margin-top: 2rem;
        margin-left: 2.5rem;
        img {
          height: 16rem;
        }
      `}
      src={image}
    />
  </div>
)

const Page1Content = ({ onNext }: { onNext: () => void }) => (
  <>
    <div
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        background-color: ${colors.hermesGreenOpaPointOne};
      `}
    >
      <TruckIcon color={colors.hermesGreen} />
    </div>
    <h3
      css={`
        font-size: 2rem;
        line-height: 2.375rem;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
        font-weight: bold;
      `}
    >
      Your SIM is on its way
    </h3>
    <>
      <Content>
        {`You can track your order from your order confirmation email. When your SIM card arrives, you can activate it from within the app, or by installing the SIM and calling 1200.`}
      </Content>
      <div
        css={`
          margin: 2rem 0;
          background-color: ${colors.darkGreyOpaPointSix};
          height: 1px;
          width: 100%;
        `}
      />
      <h3>Has your SIM already arrived?</h3>
      <p
        css={`
          margin: 1rem 2rem;
        `}
      >
        If you already have your SIM, you can activate it now in one of two ways:
      </p>
      <ul
        css={`
          padding: 0 4rem;
          font-size: 0.875rem;
          font-weight: 500;
          color: ${colors.greyText};
          > li {
            margin: 10px 0;
          }
        `}
      >
        <li>Power off your phone and install the SIM. Turn your phone on and call 1200.</li>
        <li>Activate the SIM manually using your ICCID number.</li>
      </ul>
    </>
    <div
      css={`
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        item-align: center;
      `}
    >
      <GreenButton
        css={`
          padding: 1rem 3rem;
        `}
        text={'Activate manually'}
        onClick={onNext}
      />
    </div>
  </>
)

const ListItem = ({ number, text }: { number: string; text: string }) => (
  <div
    css={`
      display: flex;
    `}
  >
    <div
      css={`
        padding: 0.5rem;
        border-radius: 2rem;
        width: 2.2rem;
        height: 2rem;
        text-align: center;
        background-color: ${colors.hermesGreenOpaPointOne};
        color: ${colors.hermesGreen};
      `}
    >
      {number}
    </div>
    <p
      css={`
        margin: 0 2rem;
      `}
    >
      {text}
    </p>
  </div>
)

const Page2Content = ({ onNext }: { onNext: () => void }) => (
  <div
    css={`
      display: flex;
      flex-direction: column;
    `}
  >
    <ListItem number={'1'} text={'Find the ICCID number on the back of your SIM card. Make a note of this number.'} />
    <ImageContainer image={IccInstructions} />
    <ListItem
      number={'2'}
      text={'Power off your phone and install the SIM card. Return to this stage and press the button below.'}
    />
    <GreenButton
      css={`
        margin-top: 4rem;
        padding: 1rem 3rem;
      `}
      text={'Next'}
      onClick={onNext}
    />
  </div>
)

const Page3Content = ({ isLoading, onRegister }: { isLoading: boolean; onRegister: (icc: string) => void }) => {
  const [icc, setIcc] = useState('')
  const validIcc = icc.length > 3

  return (
    <div
      css={`
        margin: 0 1rem;
      `}
    >
      <h2
        css={`
          margin: 1rem 3rem 4rem 0;
          font-size: 1rem;
          font-weight: 500;
          color: ${colors.textColor};
        `}
      >
        Please enter the ICCID number you found on the back of your SIM card.
      </h2>
      <div>
        <Label>{'ICCID'}</Label>
        <Textarea autoFocus={true} onChange={(e) => setIcc(e.target.value)} />
      </div>

      <GreenButton
        css={`
          margin-top: 3rem;
          padding: 1rem 3rem;
        `}
        disabled={!validIcc}
        loading={isLoading}
        text={'Activate with ICCID'}
        onClick={() => onRegister(icc)}
      />
    </div>
  )
}

enum ActivatePlanManuallyPage {
  page1,
  page2,
  page3,
}

export const ActivatePlanManuallyRoute = ({ jwtToken }: JwtTokenProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { onError } = useErrorContext()
  const { dispatch } = useAppQueryContext()

  const api = useDataLoader(postRegisterIcc)
  const { query, isLoading } = api
  const [page, setPage] = useState(ActivatePlanManuallyPage.page1)

  useEffect(() => {
    document.title = formatPageTitle('Your SIM is on its way')
  }, [t])

  return (
    <SignInWrapper>
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <BackButton onClick={() => logOut()} />
        {
          {
            0: <Page1Content onNext={() => setPage(ActivatePlanManuallyPage.page2)} />,
            1: <Page2Content onNext={() => setPage(ActivatePlanManuallyPage.page3)} />,
            2: (
              <Page3Content
                isLoading={isLoading}
                onRegister={(icc: string) => {
                  query({
                    jwtToken,
                    body: {
                      icc,
                    },
                  })
                    .then((response) =>
                      pipe(
                        response,
                        fold(
                          (error) => {
                            switch (error.type) {
                              case 'THROTTLE_ERROR':
                              case 'WRONG_PIN_CODE':
                                return
                              case 'FETCH_ERROR':
                              case 'API_ERROR':
                              case 'UNSUPPORTED_RESPONSE':
                                return onError(error, error.type)
                              case 'ABORT_ERROR':
                                return
                              case 'AUTHENTICATION_EXPIRED_ERROR':
                                return renewJwtTokenOnAuthenticationExpiredError()
                              case 'AUTHENTICATION_ERROR':
                                return redirectToSignInOnAuthenticationError()
                              default:
                                return exhaustiveCheck(error)
                            }
                          },
                          (appQueryData) => {
                            switch (appQueryData.type) {
                              case 'full-response':
                                dispatch({ type: 'activate-subscription', appQuery: appQueryData.appQuery })
                                history.push(urls.home)
                            }
                          }
                        )
                      )
                    )
                    .catch(onError)
                }}
              />
            ),
          }[page]
        }
      </div>
    </SignInWrapper>
  )
}
