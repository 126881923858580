import wretch, { Wretcher } from 'wretch'
import * as t from 'io-ts'
import { left } from 'fp-ts/es6/Either'
import { retry } from 'wretch-middlewares'
import { config } from '../config'
import { getCachedJwtRefreshToken, getCachedJwtToken, persistJwtToken, persistJwtRefreshToken } from '../storage-api'
import { urls } from '../urls'
import { exhaustiveCheck } from '../exhaustive-switch-check'
import { logOut } from '../utils/utils'
import {
  UnsupportedResponse,
  AbortError,
  FetchError,
  AuthenticationExpiredError,
  AuthenticationError,
  apiErrorValue,
  ApiError,
  ThrottleError,
  PinCodeError,
} from './api-types'

export const formatAuthorizationHeader = (token: string) => `Bearer ${token}`

interface ApiParams {
  apiType: 'auth' | 'hermes' | 'contentful-graphql' | 'local' | 'freshdesk'
  doNotRetryStatusCodes: number[]
}

// remember to list all status codes which are expected&handled by the api function
// all errors which are retried are returned as fetch error
export const externalApi = ({ apiType, doNotRetryStatusCodes }: ApiParams) => {
  const baseUrl = (() => {
    switch (apiType) {
      case 'local':
        return ''
      case 'auth':
        return config.useApiProxy ? '/auth' : config.authApiBaseUrl
      case 'hermes':
        return config.useApiProxy ? '/hermes' : config.apiBaseUrl
      case 'contentful-graphql':
        return config.contentfulGraphQlUrl
      case 'freshdesk':
        return config.freshdeskEmailTicketApiBaseUrl
      default:
        return exhaustiveCheck(apiType)
    }
  })()
  const authentication = (() => {
    switch (apiType) {
      case 'contentful-graphql':
        return formatAuthorizationHeader(config.contentfulApiKey)
      default:
        return null
    }
  })()
  return wretch()
    .resolve((_) => _.onAbort(onAbort))
    .resolve((_) => _.error(412, onWrongPinCodeError))
    .resolve((_) => _.error(429, onThrottleError))
    .resolve((_) => _.unauthorized(onAuthenticationExpiredError))
    .resolve((_) => _.fetchError(onFetchError))
    .middlewares([
      retry({
        delayTimer: 500,
        delayRamp: (delay, nbOfAttempts) => delay * nbOfAttempts,
        maxAttempts: 4,
        until: (response, error) => {
          const status = response?.status

          // never retry 401 status errors
          const isOk = !!(doNotRetryStatusCodes.concat([401]).includes(status || 0) || (response && response.ok))

          // really confusing/ugly logic related to how retry middleware causes error causes to be lost
          if (isOk) {
            return true
          }
          if (error) {
            return false
          }
          throw new Error(apiErrorValue)
        },
        retryOnNetworkError: true,
      }),
    ])
    .url(baseUrl)
    .auth(authentication ? authentication : '')
    .accept('application/json')
}

const onAbort = () => left<AbortError>({ type: 'ABORT_ERROR' })

// all errors are handled here, we use uggly logic to group errors in three groups
// aborted requests, network errors=device is offline, all other errors
const onFetchError = (error: Error, _originalRequest: Wretcher) => {
  const maybeAbortError = error as Error & { code?: number }
  if (maybeAbortError.code === DOMException.ABORT_ERR) {
    return left<AbortError>({ type: 'ABORT_ERROR' })
  }

  if (error?.message === apiErrorValue) {
    return left<ApiError>({ type: 'API_ERROR' })
  }

  return left<FetchError>({ type: 'FETCH_ERROR' })
}

export const onApiJsonValidationError = (errors: t.Errors) => {
  // eslint-disable-next-line no-console
  console.error('onApiJsonValidationError:', errors)
  return left<UnsupportedResponse>({
    type: 'UNSUPPORTED_RESPONSE',
    messages: errors.map((error) => error.context.map(({ key }) => key).join('.')),
  })
}

export const onAuthenticationExpiredError = () =>
  left<AuthenticationExpiredError>({ type: 'AUTHENTICATION_EXPIRED_ERROR' })

export const renewJwtTokenOnAuthenticationExpiredError = () => {
  const oldToken = getCachedJwtToken()
  const oldRefreshToken = getCachedJwtRefreshToken()
  wretch(`${config.authApiBaseUrl}/auth/refresh2`)
    .post({ accessToken: oldToken, refreshToken: oldRefreshToken })
    .json()
    .then((token) => {
      persistJwtToken(token.accessToken)
      persistJwtRefreshToken(token.refreshToken)
      location.href = `${location.origin}${urls.home}`
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err)
      redirectToSignInOnAuthenticationError()
    })
}

export const onThrottleError = () => left<ThrottleError>({ type: 'THROTTLE_ERROR' })

export const onWrongPinCodeError = () => left<PinCodeError>({ type: 'WRONG_PIN_CODE' })

export const onAuthenticationError = () => left<AuthenticationError>({ type: 'AUTHENTICATION_ERROR' })

export const redirectToSignInOnAuthenticationError = () => {
  logOut()
}

export const redirectToUserAuthenticatedHome = () => {
  location.href = `${location.origin}${urls.home}`
}
