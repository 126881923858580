import { left, right, fold } from 'fp-ts/es6/Either'
import { chain } from 'fp-ts/es6/TaskEither'
import { pipe } from 'fp-ts/es6/pipeable'
import { createUrlWithHost } from '../utils/url-utils'
import { urls } from '../urls'
import {
  PopulateRegistrationFormResponse,
  JwtTokenResponse,
  PostSendMagicLinkEmailRequest,
  PostSendMagicLinkEmailResponse,
  RegisterAccountRequest,
  JwtTokenSuccessResponse,
  PopulateRegistrationFormSuccessResponse,
  PostPopulateDisplayNameResponse,
  PostPopulateDisplayNameSuccessResponse,
  PostSoMeRegisterWithJsonRequest,
  PostEmailRegisterWithJsonRequest,
  PostRegisterWithJsonResponse,
  PostJwtRefreshTokenRequest,
  ReSendPinTokenResponse,
  ReSendPinTokenJsonResponse,
  ReSendPinTokenRequest,
  PostValidateMagicLinkRequest,
  PostValidateMagicLinkResponse,
  PostValidateMagicLinkJsonResponse,
  AccountProfile,
  ProfileResponse,
  GetAccountProfileRequest,
  PutAccountProfileRequest,
} from './api-types'
import { externalApi, onAuthenticationError, onApiJsonValidationError, formatAuthorizationHeader } from './api-utils'

export const getPopulateRegistrationForm = (controller: AbortController) => async (
  token: string
): Promise<PopulateRegistrationFormResponse> => {
  const url = `/auth/populateRegistrationForm/${token}`
  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
    .signal(controller)
    .url(url)
    .get()
    .json((json) =>
      pipe(
        PopulateRegistrationFormSuccessResponse.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}

export const postRegisterWithJsonForSoMe = (controller: AbortController) => async (
  body: PostSoMeRegisterWithJsonRequest
): Promise<PostRegisterWithJsonResponse> => {
  const url = `/auth/registerWithJson`

  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
    .signal(controller)
    .url(url)
    .post(body)
    .json((json) =>
      pipe(
        JwtTokenSuccessResponse.decode(json),
        fold(onApiJsonValidationError, (data) => right({ type: 'some', jwt: data }))
      )
    )
}

export const postRegisterWithJsonForEmail = (controller: AbortController) => async (
  body: PostEmailRegisterWithJsonRequest
): Promise<PostRegisterWithJsonResponse> => {
  const url = `/auth/registerWithCode`

  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
    .signal(controller)
    .url(url)
    .post(body)
    .text((_text) => right({ type: 'email', code: JSON.parse(_text), jwt: 'jwt' }))
}

export const postValidateMagicLink = (controller: AbortController) => (
  requestData: PostValidateMagicLinkRequest
): Promise<PostValidateMagicLinkJsonResponse> => {
  const url = `/authBackend/validateMagicLink`

  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [412] })
    .signal(controller)
    .headers({ 'x-api-key': requestData.apiKey })
    .url(url)
    .post({
      magicLinkResendToken: requestData.magicLinkResendToken,
      magicLinkValidationCode: requestData.magicLinkValidationCode,
    })
    .json((json) =>
      pipe(
        PostValidateMagicLinkResponse.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}

export const putResendMagicLink = (controller: AbortController) => (
  requestData: ReSendPinTokenRequest
): Promise<ReSendPinTokenJsonResponse> => {
  const url = `/authBackend/resendMagicLinkEmail`

  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [429] })
    .signal(controller)
    .headers({ 'x-api-key': requestData.apiKey })
    .url(url)
    .put({ token: requestData.token, email: requestData.email })
    .json((json) =>
      pipe(
        ReSendPinTokenResponse.decode({ code: json }),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}

export const registerAccountWithSoMeApi = (singleUseToken: string) => (controller: AbortController) => ({
  firstName,
  lastName,
  country,
  marketingConsent,
}: RegisterAccountRequest): Promise<PostRegisterWithJsonResponse> => {
  const first = () => getPopulateRegistrationForm(controller)(singleUseToken)
  const userAgent = navigator.userAgent
  const referer = document.referrer
  const refererExists = !!referer && referer !== ''
  const second = ({ token, displayName, email }: PopulateRegistrationFormSuccessResponse) => () =>
    postRegisterWithJsonForSoMe(controller)({
      token,
      displayName,
      branding: 'Hermes',
      email,
      returnJwt: true,
      termsAccepted: true,
      marketingConsent,
      country,
      firstName,
      lastName,
      loginData: refererExists
        ? {
          program: 'Hermes',
          userAgent,
          referer,
        }
        : {
          program: 'Hermes',
          userAgent,
        },
    })

  return pipe(first, chain(second))()
}

export const registerAccountWithEmailApi = (email: string) => (controller: AbortController) => ({
  firstName,
  lastName,
  country,
  marketingConsent,
}: RegisterAccountRequest): Promise<PostRegisterWithJsonResponse> => {
  const first = () => postPopulateDisplayName(controller)({ email })
  const userAgent = navigator.userAgent
  const referer = document.referrer
  const refererExists = !!referer && referer !== ''

  const second = ({ displayName }: PostPopulateDisplayNameSuccessResponse) => () =>
    postRegisterWithJsonForEmail(controller)({
      displayName,
      branding: 'Hermes',
      email,
      returnJwt: true,
      termsAccepted: true,
      marketingConsent,
      showPinCode: true,
      provider: 'Email',
      redirectUri: createUrlWithHost(urls.registeredUserRedirect),
      country,
      firstName,
      lastName,
      loginData: refererExists
        ? {
          program: 'Hermes',
          userAgent,
          referer,
        }
        : {
          program: 'Hermes',
          userAgent,
        },
    })
  return pipe(first, chain(second))()
}

export const getJwtToken = (controller: AbortController) => async (token: string): Promise<JwtTokenResponse> => {
  const url = `/auth/token2/${token}`
  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [409] })
    .signal(controller)
    .url(url)
    .get()
    .error(409, onAuthenticationError)
    .json((json) =>
      pipe(
        JwtTokenSuccessResponse.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}

export const postJwtRefreshToken = () => async (requestData: PostJwtRefreshTokenRequest): Promise<JwtTokenResponse> => {
  const url = `/auth/refresh2`

  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] }).url(url).post(requestData)
}

export const postSendMagicLinkEmail = (controller: AbortController) => (
  requestData: PostSendMagicLinkEmailRequest
): Promise<PostSendMagicLinkEmailResponse> => {
  const url = `/authBackend/sendMagicLinkEmail`

  return (
    externalApi({ apiType: 'auth', doNotRetryStatusCodes: [412] })
      .signal(controller)
      .headers({ 'x-api-key': requestData.apiKey })
      .url(url)
      .post(requestData.body)
      // "Login with no account existing, email foo.bar@reaktor.fi"
      .error(412, () => left({ type: 'NO_ACCOUNT' }))
      .text((_text) => right({ type: 'email', code: JSON.parse(_text) }))
  )
}

export const postPopulateDisplayName = (controller: AbortController) => (body: {
  email: string
}): Promise<PostPopulateDisplayNameResponse> => {
  const url = `/auth/populateDisplayName`

  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
    .signal(controller)
    .url(url)
    .post(body)
    .json((json) =>
      pipe(
        PostPopulateDisplayNameSuccessResponse.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}

export const getAccountProfile = (controller: AbortController) => async ({
  jwtToken,
}: GetAccountProfileRequest): Promise<ProfileResponse> => {
  const url = `/api/Profile/partial/`

  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
    .signal(controller)
    .auth(formatAuthorizationHeader(jwtToken))
    .url(url)
    .get()
    .json((json) =>
      pipe(
        AccountProfile.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}

export const putAccountProfile = (controller: AbortController) => async ({
  body,
  jwtToken,
}: PutAccountProfileRequest): Promise<ProfileResponse> => {
  const url = `/api/Profile/partial/`

  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
    .signal(controller)
    .auth(formatAuthorizationHeader(jwtToken))
    .url(url)
    .put(body)
    .json((json) =>
      pipe(
        AccountProfile.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}
