import { Either } from 'fp-ts/es6/Either'
import * as t from 'io-ts'

export interface UnsupportedResponse {
  type: 'UNSUPPORTED_RESPONSE'
  messages: string[]
}

export interface AbortError {
  type: 'ABORT_ERROR'
}

export interface FetchError {
  type: 'FETCH_ERROR'
  status?: number
}

export const apiError = {
  API_ERROR: {},
}
export const apiErrorValue = Object.keys(apiError)[0]
export interface ApiError {
  type: keyof typeof apiError
}

export interface PinCodeError {
  type: 'WRONG_PIN_CODE'
}

export interface ThrottleError {
  type: 'THROTTLE_ERROR'
}

export interface AuthenticationExpiredError {
  type: 'AUTHENTICATION_EXPIRED_ERROR'
}

export interface AuthenticationError {
  type: 'AUTHENTICATION_ERROR'
}

export type BaseApiError =
  | AuthenticationExpiredError
  | AuthenticationError
  | AbortError
  | FetchError
  | ApiError
  | ThrottleError
  | PinCodeError
export type CommonApiError = BaseApiError | UnsupportedResponse

export interface ApiParams {
  [index: string]: any
}

export interface JwtApiParams extends ApiParams {
  jwtToken: string
}

export type PostAppQueryRequest = JwtApiParams

export type GetAccountProfileRequest = JwtApiParams

export type GetEcologyRequest = JwtApiParams

export interface PutAccountProfileRequest extends JwtApiParams {
  body: AccountProfile
}

export interface PostRegisterWithJsonRequestCommon {
  email: string
  displayName: string
  returnJwt: true
  termsAccepted: true
  country: string
  branding: 'Hermes'
  loginData: {
    program: 'Hermes'
    referer?: string
    userAgent?: string
    ipAddress?: string
  }
  firstName: null | string
  lastName: null | string
  marketingConsent: boolean
}

export interface PostSoMeRegisterWithJsonRequest extends PostRegisterWithJsonRequestCommon {
  token: string
}

export interface PostEmailRegisterWithJsonRequest extends PostRegisterWithJsonRequestCommon {
  provider: 'Email'
  showPinCode: boolean
  redirectUri: string
}

export interface PostRegisterWithJsonForSomeResponse {
  type: 'some'
  jwt: JwtTokenSuccessResponse
}

export const MagicLinkValidationResponse = t.interface({
  status: t.string,
})

export type MagicLinkValidationResponse = t.TypeOf<typeof MagicLinkValidationResponse>

export type MagicLinkValidationJsonResponse = Either<CommonApiError, MagicLinkValidationResponse>

export const PostValidateMagicLinkRequest = t.interface({
  magicLinkResendToken: t.string,
  magicLinkValidationCode: t.string,
  apiKey: t.string,
})

export type PostValidateMagicLinkRequest = t.TypeOf<typeof PostValidateMagicLinkRequest>

export const ReSendPinTokenRequest = t.interface({
  token: t.string,
  email: t.string,
  apiKey: t.string,
})

export type ReSendPinTokenRequest = t.TypeOf<typeof ReSendPinTokenRequest>

export const PostValidateMagicLinkResponse = t.interface({
  accessToken: t.string,
})

export type PostValidateMagicLinkResponse = t.TypeOf<typeof PostValidateMagicLinkResponse>

export type PostValidateMagicLinkJsonResponse = Either<CommonApiError, PostValidateMagicLinkResponse>

export const ReSendPinTokenResponse = t.interface({
  code: t.string,
})

export type ReSendPinTokenResponse = t.TypeOf<typeof ReSendPinTokenResponse>

export type ReSendPinTokenJsonResponse = Either<CommonApiError, ReSendPinTokenResponse>

export const PostRegisterWithCodeResponse = t.interface({
  type: t.string,
  code: t.string,
  jwt: t.any,
})

export type PostRegisterWithCodeResponse = t.TypeOf<typeof PostRegisterWithCodeResponse>

export type PostRegisterWithJsonResponse = Either<
  CommonApiError,
  PostRegisterWithJsonForSomeResponse | PostRegisterWithCodeResponse
>

export interface RegisterAccountRequest extends ApiParams {
  firstName: null | string
  lastName: null | string
  country: string
  marketingConsent: boolean
}

export const PopulateRegistrationFormSuccessResponse = t.interface({
  accessToken: t.string,
  displayName: t.string,
  email: t.string,
  token: t.string,
})
export type PopulateRegistrationFormSuccessResponse = t.TypeOf<typeof PopulateRegistrationFormSuccessResponse>

export type PopulateRegistrationFormResponse = Either<CommonApiError, PopulateRegistrationFormSuccessResponse>

export const JwtTokenSuccessResponse = t.interface({
  accessToken: t.string,
  refreshToken: t.string,
})
export type JwtTokenSuccessResponse = t.TypeOf<typeof JwtTokenSuccessResponse>

export type JwtTokenResponse = Either<CommonApiError, JwtTokenSuccessResponse>

export interface PostJwtRefreshTokenRequest {
  accessToken: string
  refreshToken: string
}

export interface RegisterWithJsonRequest {
  returnJwt: boolean
  termsAccepted: boolean
  displayName: string
  marketingConsent: boolean
  token: string
  email: string
}

export const AccountProfile = t.intersection([
  t.partial({
    firstName: t.string,
    lastName: t.string,
    country: t.string,
    newsletterOptIn: t.boolean,
    phoneNumber: t.string,
  }),
  t.interface({
    email: t.string,
    displayName: t.string,
  }),
])

export const EcologyHistory = t.interface({
  createdAt: t.string,
  plantedBy: t.string,
  plantedTreeCount: t.number,
  ecologiLinkUrl: t.string
})

export const EcologyQuery = t.interface({
  eligibleTreeCount: t.number,
  ecologiHistory: t.array(EcologyHistory)
})


export type AccountProfile = t.TypeOf<typeof AccountProfile>
export type EcologyQuery = t.TypeOf<typeof EcologyQuery>

export type ProfileResponse = Either<CommonApiError, AccountProfile>
export type EcologyResponse = Either<CommonApiError, EcologyQuery>

const CardType = t.union([
  t.keyof({
    visa: null,
    mastercard: null,
    amex: null,
  }),
  t.string,
  t.null,
])
export type CardType = t.TypeOf<typeof CardType>

const SubsciptionQuota = t.interface({
  data: t.number,
  credit: t.number,
})

type SubsciptionQuota = t.TypeOf<typeof SubsciptionQuota>

export const Subscriber = t.interface({
  email: t.string,
  first_name: t.string,
  last_name: t.string,
})
export type Subscriber = t.TypeOf<typeof Subscriber>

export const HermesPlan = t.interface({
  id: t.string,
  effective_date: t.string,
  expiration_date: t.string,
  quota: SubsciptionQuota,
  balance: SubsciptionQuota,
  name: t.string,
})

export type HermesPlan = t.TypeOf<typeof HermesPlan>

export const Subscription = t.interface({
  totalBalanceData: t.number,
  totalQuotaData: t.number,
  totalUsedData: t.number,
  expirationDate: t.string,
  icc: t.string,
  msisdn: t.string,
  pin1: t.string,
  pin2: t.string,
  puk1: t.string,
  puk2: t.string,
  queuedPlans: t.union([t.null, t.array(HermesPlan)]),
  addOnPlans: t.union([t.null, t.array(HermesPlan)]),
})

export type Subscription = t.TypeOf<typeof Subscription>

export const HermesPaymentMethod = t.interface({
  brand: CardType,
  expiry_month: t.number,
  expiry_year: t.number,
  last_4: t.string,
  token: t.string,
})

export type HermesPaymentMethod = t.TypeOf<typeof HermesPaymentMethod>

export const PurchasablePlan = t.intersection([
  t.partial({
    currentlySelected: t.boolean,
  }),
  t.interface({
    productId: t.number,
    name: t.string,
    price: t.number,
    currency: t.string,
    duration: t.number,
    durationType: t.string,
    quotaData: t.number,
    ecologiTreeCount: t.union([t.number, t.null, t.undefined]),
  }),
])

export type PurchasablePlan = t.TypeOf<typeof PurchasablePlan>

export const RecentOrder = t.interface({
  dateCreated: t.string,
})

export type RecentOrder = t.TypeOf<typeof RecentOrder>

export const InAppOrder = t.interface({
  orderDate: t.string,
  product: PurchasablePlan,
})

export type InAppOrder = t.TypeOf<typeof InAppOrder>

export const AlternateAccounts = t.interface({
  username: t.string,
  uid: t.string,
  icc: t.string,
  msisdn: t.string,
  currentlySelected: t.boolean,
})

export const AppQuery = t.partial({
  subscriber: Subscriber,
  paymentMethods: t.union([t.null, t.array(HermesPaymentMethod)]),
  recentOrders: t.union([t.null, t.array(RecentOrder)]),
  inAppOrders: t.union([t.null, t.array(InAppOrder)]),
  pendingActivation: t.string,
  subscription: t.union([t.null, Subscription]),
  renewalAlternatives: t.union([t.null, t.array(PurchasablePlan)]),
  addOnProductAlternatives: t.union([t.null, t.array(PurchasablePlan)]),
  allowPortInRequest: t.union([t.null, t.boolean]),
  alternateAccounts: t.union([t.null, t.undefined, t.array(AlternateAccounts)]),
})

export type AppQuery = t.TypeOf<typeof AppQuery>

export interface AppQuerySuccessResponse {
  type: 'full-response'
  appQuery: AppQuery
}

export type AppQueryResponse = Either<CommonApiError, AppQuerySuccessResponse>

export const Country = t.intersection([
  t.partial({
    zoneName: t.string,
    exchangeRate: t.number,
  }),
  t.type({
    name: t.string,
    twoLetterCode: t.string,
    threeLetterCode: t.string,
    numericCode: t.string,
    supported: t.boolean,
  }),
])

export type Country = t.TypeOf<typeof Country>
export const GetCountryListSuccessResponse = t.array(Country)
export type GetCountryListSuccessResponse = t.TypeOf<typeof GetCountryListSuccessResponse>
export type GetCountryListResponse = Either<CommonApiError, GetCountryListSuccessResponse>

export const FormattedLocalisations = t.record(t.string, t.string)
export type FormattedLocalisations = t.TypeOf<typeof FormattedLocalisations>

export interface UpdateRenewalPlanRequest extends JwtApiParams {
  body: { productId: number }
}

export type UpdateRenewalPlanResponse = Either<CommonApiError, AppQuerySuccessResponse>

export const PurchaseProductSuccessResponse = t.interface({
  createOrderResponse: t.interface({
    hmdOrderNumber: t.string,
    storeDomain: t.string,
    status: t.string,
    orderId: t.number,
    statusId: t.number,
  }),
})
export type PurchaseProductSuccessResponse = t.TypeOf<typeof PurchaseProductSuccessResponse>

export type PurchaseProductResponse = Either<CommonApiError, PurchaseProductSuccessResponse>

export interface PurchaseProductRequest extends JwtApiParams {
  body: { productId: number }
}

export interface PostSendMagicLinkEmailRequest extends ApiParams {
  apiKey: string
  body: {
    email: string
    branding: 'Hermes'
    loginData: {
      uuid: null
      program: 'Hermes'
      referer: null | string
      userAgent: string
      ipAddress: null | string
      representativeTribeId: null | string
      mccMnc: null | string
    }
    fcmToken: null
    redirectUri: string
    showPinCode: true
  }
}

export const PostSendMagicLinkEmailSuccessResponse = t.interface({
  type: t.string,
  code: t.string,
})

export type PostSendMagicLinkEmailSuccessResponse = t.TypeOf<typeof PostSendMagicLinkEmailSuccessResponse>

export interface PostSendMagicLinkEmailNoAccountResponse {
  type: 'NO_ACCOUNT'
}

export type PostSendMagicLinkEmailResponse = Either<
  BaseApiError | PostSendMagicLinkEmailNoAccountResponse,
  PostSendMagicLinkEmailSuccessResponse
>

export const PostPopulateDisplayNameSuccessResponse = t.interface({
  displayName: t.string,
})
export type PostPopulateDisplayNameSuccessResponse = t.TypeOf<typeof PostPopulateDisplayNameSuccessResponse>

export type PostPopulateDisplayNameResponse = Either<CommonApiError, PostPopulateDisplayNameSuccessResponse>

export type GetVersionResponse = Either<BaseApiError, { type: 'version'; version: string }>

const Value = t.type({
  string: t.string,
})
type Value = t.TypeOf<typeof Value>

const ContentfulKeyValuePair = t.type({
  key: t.string,
  value: Value,
})
type ContentfulKeyValuePair = t.TypeOf<typeof ContentfulKeyValuePair>

const KeyValuePairsCollection = t.type({
  items: t.array(ContentfulKeyValuePair),
})
type KeyValuePairsCollection = t.TypeOf<typeof KeyValuePairsCollection>

const Sys = t.type({
  publishedVersion: t.unknown,
})
type Sys = t.TypeOf<typeof Sys>

const Items = t.type({
  sys: Sys,
  keyValuePairsCollection: KeyValuePairsCollection,
})
type Items = t.TypeOf<typeof Items>

const AppContentCollection = t.type({
  items: t.array(Items),
})
type AppContentCollection = t.TypeOf<typeof AppContentCollection>

const ContentfulData = t.type({
  appContentCollection: AppContentCollection,
})
type ContentfulData = t.TypeOf<typeof ContentfulData>

export const ContentfulLocalisationResponse = t.type({
  data: ContentfulData,
})
export type ContentfulLocalisationResponse = t.TypeOf<typeof ContentfulLocalisationResponse>

export const ContentfulErrorResponse = t.type({
  data: t.object,
  error: t.array(t.object),
})
export type ContentfulErrorResponse = t.TypeOf<typeof ContentfulErrorResponse>

// Freshdesk
export interface PostCreateFreshdeskTicketRequest {
  name: string
  phone: string
  email: string
  status: 2
  priority: 1
  subject: string
  description: string
  custom_fields: {
    country: 'United Kingdom'
    category: 'HMD Mobile'
    subcategory: '...'
    cf_product_group_division: 'Connectivity'
    cf_product_unit: 'HMD Mobile'
  }
  source: 1
  tags: ['en_Hermes']
}

export const PostCreateFreshdeskTicketSuccessResponse = t.interface({
  id: t.number,
})

export type PostCreateFreshdeskTicketSuccessResponse = t.TypeOf<typeof PostCreateFreshdeskTicketSuccessResponse>

export type PostCreateFreshdeskTicketResponse = Either<UnsupportedResponse, PostCreateFreshdeskTicketSuccessResponse>

export interface PostNetworkSwitchRequest extends JwtApiParams {
  body: {
    pac: string
    msisdn: string
  }
}

export interface AccountSelectionRequest extends JwtApiParams {
  body: {
    uid: string
    msisdn: string
  }
}

export type PostNetworkSwitchResponse = Either<CommonApiError, void>

export interface PostRegisterIccRequest extends JwtApiParams {
  body: {
    icc: string
  }
}

export type PostRegisterIccResponse = Either<CommonApiError, AppQuerySuccessResponse>

export interface PostActivatePendingSubscriptionRequest extends JwtApiParams {
  body: {
    activationId: string
  }
}

export type PostActivatePendingSubscriptionResponse = Either<CommonApiError, AppQuerySuccessResponse>

export type PostStopAutoRenewRequest = JwtApiParams

export type PostStopAutoRenewResponse = Either<CommonApiError, AppQuerySuccessResponse>
