import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { InternalLink } from '../common/InternalLink'
import { defaultBorderRadius } from '../styles/defaultBorderRadius'
import { config } from '../../config'
import { LeftArrowIcon } from '../../icons/LeftArrowIcon'

const Icon = styled(LeftArrowIcon)`
  transform: rotate(135deg);
`

export const BuySimLink = () => {
  const { t } = useTranslation()

  return (
    <InternalLink
      href={config.checkoutUrl}
      css={`
        background: linear-gradient(225deg, #4fabf7 0%, #a7d5fc 100%);
        padding: 1.5rem;
        border-radius: ${defaultBorderRadius};
        text-decoration: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        margin-top: 1.875rem;
        &:hover {
          opacity: 0.7;
        }
      `}
    >
      <>
        <div>
          <div
            css={`
              font-size: 1.5rem;
              font-weight: 700;
              color: #fff;
              margin-bottom: 0.5rem;
            `}
          >
            {t('buySimLink.needASIMCard')}
          </div>
          <div
            css={`
              color: #fff;
              font-weight: 500;
            `}
          >
            {'Order now'}
          </div>
        </div>
        <div
          css={`
            margin-left: auto;
            width: 3.125rem;
            height: 3.125rem;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Icon color={'#fff'} />
        </div>
      </>
    </InternalLink>
  )
}
