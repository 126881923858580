import { useEffect } from 'react'
import { pipe } from 'fp-ts/es6/pipeable'
import { fold } from 'fp-ts/es6/Either'
import { getVersion } from '../api/api'
import { persistNewVersionAvailable } from '../storage-api'
import { config } from '../config'
import { OnUpdateAccept } from '../types'

export const useVersionCheck = (
  setOnUpdateAccepted: React.Dispatch<React.SetStateAction<{ onAccept: null | OnUpdateAccept }>>
) => {
  useEffect(() => {
    let lastVersionCheck = Date.now()
    const onVisibilityChange = () => {
      const timeFromLastCheck = Date.now() - lastVersionCheck
      const checkInterval = 30 * 60 * 1000 // 30min

      if (!document.hidden && timeFromLastCheck > checkInterval) {
        lastVersionCheck = Date.now()
        getVersion()
          .then((response) => {
            pipe(
              response,
              fold(
                (_error) => {},
                (data) => {
                  if (data.version !== config.appVersion) {
                    persistNewVersionAvailable()
                    setOnUpdateAccepted({ onAccept: () => Promise.resolve(location.reload(true)) })
                  }
                }
              )
            )
          })
          .catch(() => {
            // NOOP
          })
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)
  }, [setOnUpdateAccepted])
}
