export const colors = {
  hermesGrey: '#E6E8EA',
  hermesGreen: '#4FABF7',
  hermesGreenOpaPointOne: 'rgba(79, 171, 247, 0.1)',
  hermesGreenOpaPointSix: 'rgba(79, 171, 247, 0.6)',
  lightGreen: '#F3F5F8',
  gunPowder: '#484852',
  errorRed: '#f94a61',
  warningRed: '#EF4E52',
  warningRedOpaPointOne: 'rgba(239,78,82, 0.1)',
  textColor: '#202124',
  greyText: '#8890A1',
  lightGrey: '#E6E8EA',
  bgColor: '#F3F5F8',
  borderColor: '#E6F5F5',
  newBorderColor: '#C2CDDB',
  darkGreyBorder: '#E6E9EA',
  darkRed: '#A73E4B',
  darkGrey: '#001E2D',
  darkGreyOpaPointSix: 'rgb(0, 30, 45, 0.6)',
  darkGreyText: '#4d616c',
  concrete: '#F1F3F3',
  white: '#FFFFFF',
  red: '#CB4344',
  orange: 'rgba(242, 112, 66, 1)',
  yellow: '#FFCC31',
  teal: '#7BC5C8',
  deepBlueDim: '#C2CDDB',
  HMDBlue: '#081D2C',
  brightBlue: '#4FABF7',
  deepBlue: '#E6EBF0',
}

export const containerWidths = {
  sm: 320,
  modernPhone: 375,
  md: 769,
  lg: 1024,
  xl: 1280,
}
