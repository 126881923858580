import React from 'react'
import SimCard from '../images/hmd_sim.png'
import { Picture } from '../components/common/Picture'

export const SidebarSim = () => (
  <Picture
    css={`
      img {
        height: 7rem;
      }
    `}
    src={SimCard}
  />
)
