import React from 'react'
import { Icon } from '../types'

export const AppleIcon = (props: Icon) => (
  <svg className={props.className} width={'24px'} height={'24px'} viewBox="0 0 24 24">
    <title>Apple</title>
    <path
      fill="#001E2D"
      d="M12.25 4.97c.95 0 2.13-.66 2.83-1.53.64-.8 1.1-1.9 1.1-3.02 0-.15-.01-.3-.04-.42a4.6 4.6 0 00-3.06 1.64c-.6.7-1.15 1.8-1.15 2.92 0 .16.03.33.04.38.07.02.18.03.28.03zM8.93 21.53c1.29 0 1.86-.89 3.47-.89 1.63 0 2 .87 3.42.87 1.41 0 2.36-1.35 3.25-2.66 1-1.5 1.4-2.98 1.43-3.05a4.71 4.71 0 01-2.79-4.36c0-2.76 2.13-4 2.25-4.1a4.86 4.86 0 00-4.14-2.14c-1.58 0-2.86.99-3.67.99-.88 0-2.04-.93-3.4-.93-2.6 0-5.25 2.21-5.25 6.4 0 2.6.98 5.36 2.2 7.14 1.03 1.5 1.93 2.73 3.23 2.73z"
    />
  </svg>
)
