import React from 'react'

interface Props {
  href: string
  title?: string
  className?: string
  children: React.ReactElement
}

export const InternalLink = ({ href, title, className, children }: Props) => (
  <a className={className} title={title} target="_self" href={href}>
    {children}
  </a>
)
