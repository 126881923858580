import { createGlobalStyle } from 'styled-components'
import { colors } from '../../theme'

import DMSansRegularWoff from '../../fonts/dm-sans-v10-latin/dm-sans-v10-latin-regular.woff'
import DMSansRegularWoff2 from '../../fonts/dm-sans-v10-latin/dm-sans-v10-latin-regular.woff2'

import DMSans500Woff from '../../fonts/dm-sans-v10-latin/dm-sans-v10-latin-500.woff'
import DMSans500Woff2 from '../../fonts/dm-sans-v10-latin/dm-sans-v10-latin-500.woff2'

import DMSans700Woff from '../../fonts/dm-sans-v10-latin/dm-sans-v10-latin-700.woff'
import DMSans700Woff2 from '../../fonts/dm-sans-v10-latin/dm-sans-v10-latin-700.woff2'

export const GlobalStyle = createGlobalStyle`
/* dm-sans-regular - latin */
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src:
      url(${DMSansRegularWoff}) format('woff'),
      url(${DMSansRegularWoff2}) format('woff2');
  }

  /* dm-sans-500 - latin */
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: 
      url(${DMSans500Woff2}) format('woff2'),
      url(${DMSans500Woff}) format('woff'),
  }

  /* dm-sans-700 - latin */
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    src: 
      url(${DMSans700Woff2}) format('woff2'),
      url(${DMSans700Woff}) format('woff'),
  }

  * {
    font-family: 'DM Sans', 'Open Sans', sans-serif;
  }

  html,body{
    overscroll-behavior-x: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin:0;
    padding:0;
    height:100%;
    user-select: none;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    position: relative;
    background-color: #fff;
    color: ${colors.textColor};
  }
  a {
    color: #D8D8D8;
    &:hover{
      opacity: 0.7;
    }
  }
`
