import React from 'react'
import { IconWithColor } from '../types'

export const MobileIcon = (props: IconWithColor) => (
  <svg className={props.className} width="22px" height="34px" viewBox="0 0 22 34">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-300.000000, -162.000000)" fill={props.color}>
        <g transform="translate(281.000000, 149.000000)">
          <g transform="translate(12.000000, 12.000000)">
            <g transform="translate(7.500000, 1.500000)">
              <path d="M16.5,0 L4.5,0 C2.01,0 0,2.01 0,4.5 L0,28.5 C0,30.99 2.01,33 4.5,33 L16.5,33 C18.99,33 21,30.99 21,28.5 L21,4.5 C21,2.01 18.99,0 16.5,0 Z M18,25.5 L3,25.5 L3,4.5 L18,4.5 L18,25.5 Z M13.5,30 L7.5,30 L7.5,28.5 L13.5,28.5 L13.5,30 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
