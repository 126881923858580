import * as queryString from 'query-string'

const authenticatedUserBaseUrl = '/reg'

const urlsWithParams = {
  selectedPackage: `${authenticatedUserBaseUrl}/package/:globalId`,
  createAccountWithSome: '/create-account',
  createAccountWithEmail: '/create-account-email',
  enterEmailLoginPinCode: '/enter-email-pin-code',
  activatePlan: `/activate-plan`,
  availablePlans: `${authenticatedUserBaseUrl}/available-plans`,
  userPackages: `${authenticatedUserBaseUrl}/user-packages`,
  paymentMethods: `${authenticatedUserBaseUrl}/paymentmethods`,
}

export const urls = {
  signIn: '/',
  createAccountWithSome: {
    url: urlsWithParams.createAccountWithSome,
    clientUrl: (t?: string) => {
      const queryParams = queryString.stringify({ t })
      return queryParams
        ? `${urlsWithParams.createAccountWithSome}?${queryParams}`
        : urlsWithParams.createAccountWithSome
    },
  },
  createAccountWithEmail: {
    url: urlsWithParams.createAccountWithEmail,
    clientUrl: (email: string) => `${urlsWithParams.createAccountWithEmail}?${queryString.stringify({ email })}`,
  },
  enterEmailLoginPinCode: {
    url: urlsWithParams.enterEmailLoginPinCode,
    clientUrl: (email: string, code: string) =>
      `${urlsWithParams.enterEmailLoginPinCode}?${queryString.stringify({ email })}&${queryString.stringify({ code })}`,
  },
  registeredUserRedirect: '/registered-user-redirect',
  authenticatedUserBaseUrl,
  activateManually: '/activate-manually',
  activatePlan: {
    url: urlsWithParams.activatePlan,
    clientUrl: (activationId: string) => `${urlsWithParams.activatePlan}?${queryString.stringify({ activationId })}`,
  },
  purchaseHistory: `${authenticatedUserBaseUrl}/purchases`,
  paymentMethods: {
    url: urlsWithParams.paymentMethods,
    clientUrl: (from: string, label: string) => {
      const queryParams = queryString.stringify({ from, label })
      return queryParams ? `${urlsWithParams.paymentMethods}?${queryParams}` : urlsWithParams.paymentMethods
    },
  },
  cancelPlan: `${authenticatedUserBaseUrl}/cancel-plan`,
  account: `${authenticatedUserBaseUrl}/account`,
  ecologi: `${authenticatedUserBaseUrl}/ecologi`,
  home: `${authenticatedUserBaseUrl}/home`,
  support: {
    root: `${authenticatedUserBaseUrl}/support`,
    index: {
      url: '/',
      clientUrl: () => `${authenticatedUserBaseUrl}/support`,
    },
    sendEmail: {
      url: `${authenticatedUserBaseUrl}/support/send-email`,
      clientUrl: () => `${authenticatedUserBaseUrl}/support/send-email`,
    },
    liveChat: {
      url: `${authenticatedUserBaseUrl}/support/live-chat`,
      clientUrl: () => `${authenticatedUserBaseUrl}/support/live-chat`,
    },
    networkSwitch: {
      url: `${authenticatedUserBaseUrl}/support/network-switch`,
      clientUrl: () => `${authenticatedUserBaseUrl}/support/network-switch`,
    },
  },
  queuedPlans: `${authenticatedUserBaseUrl}/queuedPlans`,
  availablePlans: {
    url: urlsWithParams.availablePlans,
    clientUrl: (packageId?: string, addOn?: boolean) => {
      const queryParams = queryString.stringify({ packageId, addOn })
      return queryParams ? `${urlsWithParams.availablePlans}?${queryParams}` : urlsWithParams.availablePlans
    },
  },
}
