import { fold, right } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import { JwtTokenProps } from '../types'
import {
  AccountSelectionRequest,
  AppQuery,
  AppQueryResponse,
  EcologyQuery,
  EcologyResponse,
  GetCountryListResponse,
  GetCountryListSuccessResponse,
  GetEcologyRequest,
  GetVersionResponse,
  PostActivatePendingSubscriptionRequest,
  PostActivatePendingSubscriptionResponse,
  PostAppQueryRequest,
  PostNetworkSwitchRequest,
  PostNetworkSwitchResponse,
  PostRegisterIccRequest,
  PostRegisterIccResponse,
  PostStopAutoRenewRequest,
  PostStopAutoRenewResponse
} from './api-types'
import { externalApi, formatAuthorizationHeader, onApiJsonValidationError } from './api-utils'

const _handleAppQueryResponse = (text: string): AppQueryResponse => {
  if (text.length > 0) {
    return pipe(
      AppQuery.decode(JSON.parse(text)),
      fold(onApiJsonValidationError, (data) => right({ type: 'full-response', appQuery: data }))
    )
  }
  return right({
    type: 'full-response',
    appQuery: {},
  })
}

export const postAppQuery =
  (controller: AbortController) =>
    async (postAppQueryRequest: PostAppQueryRequest): Promise<AppQueryResponse> => {
      const url = '/hermes/AppRegisteredUser/appQuery'

      return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
        .url(url)
        .signal(controller)
        .auth(formatAuthorizationHeader(postAppQueryRequest.jwtToken))
        .post({})
        .text(_handleAppQueryResponse)
    }

export const selectActiveAccount =
  (controller: AbortController) =>
    async ({ jwtToken, body }: AccountSelectionRequest): Promise<AppQueryResponse> => {
      const url = '/hermes/AppRegisteredUser/selectActiveAccount'

      return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
        .url(url)
        .signal(controller)
        .auth(formatAuthorizationHeader(jwtToken))
        .put(body)
        .text(_handleAppQueryResponse)
    }

export const postNetworkSwitchRequest =
  (controller: AbortController) =>
    async ({ jwtToken, body }: PostNetworkSwitchRequest): Promise<PostNetworkSwitchResponse> => {
      const url = '/hermes/AppRegisteredUser/portInRequest'

      return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
        .url(url)
        .signal(controller)
        .auth(formatAuthorizationHeader(jwtToken))
        .post(body)
        .json()
    }

export const postRegisterIcc =
  (controller: AbortController) =>
    async ({ jwtToken, body }: PostRegisterIccRequest): Promise<PostRegisterIccResponse> => {
      const url = '/hermes/AppRegisteredUser/registerIcc'

      return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
        .url(url)
        .signal(controller)
        .auth(formatAuthorizationHeader(jwtToken))
        .post(body)
        .text(_handleAppQueryResponse)
    }

export const postActivatePendingSubscription =
  (controller: AbortController) =>
    async ({
      jwtToken,
      body,
    }: PostActivatePendingSubscriptionRequest): Promise<PostActivatePendingSubscriptionResponse> => {
      const url = '/hermes/AppRegisteredUser/activatePendingSubscription'

      return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
        .url(url)
        .signal(controller)
        .auth(formatAuthorizationHeader(jwtToken))
        .post(body)
        .text(_handleAppQueryResponse)
    }

export const postStopAutoRenew =
  (controller: AbortController) =>
    async ({ jwtToken }: PostStopAutoRenewRequest): Promise<PostStopAutoRenewResponse> => {
      const url = '/hermes/AppRegisteredUser/stopAutoRenewal'

      return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
        .url(url)
        .signal(controller)
        .auth(formatAuthorizationHeader(jwtToken))
        .post({})
        .text(_handleAppQueryResponse)
    }

export const getCountryList =
  (controller: AbortController) =>
    ({ language, apiKey }: { language: string; apiKey: string }): Promise<GetCountryListResponse> => {
      const url = `/hermes/Products/countryList?lang=${language}`

      return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
        .headers({ 'x-api-key': apiKey })
        .url(url)
        .signal(controller)
        .get()
        .json((json) =>
          pipe(
            GetCountryListSuccessResponse.decode(json),
            fold(onApiJsonValidationError, (data) => right(data))
          )
        )
    }

export const fetchEcologiQuery = (controller: AbortController) => async ({
  jwtToken,
}: GetEcologyRequest): Promise<EcologyResponse> => {
  const url = `/hermes/AppRegisteredUser/ecologiQuery`

  return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
    .signal(controller)
    .auth(formatAuthorizationHeader(jwtToken))
    .url(url)
    .post()
    .json((json) =>
      pipe(
        EcologyQuery.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}

export const plantTrees = (controller: AbortController) => async ({
  jwtToken,
}: GetEcologyRequest): Promise<EcologyResponse> => {
  const url = `/hermes/AppRegisteredUser/ecologiPlantTrees`

  return externalApi({ apiType: 'hermes', doNotRetryStatusCodes: [] })
    .signal(controller)
    .auth(formatAuthorizationHeader(jwtToken))
    .url(url)
    .post()
    .json((json) =>
      pipe(
        EcologyQuery.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}



// TODO add dataLoader support if this is used in production
export const deleteMyData = async ({ jwtToken }: JwtTokenProps) => {
  const url = '/hydra/AppRegisteredUser/deleteMyData?alsoRegistration=true'
  return externalApi({ apiType: 'auth', doNotRetryStatusCodes: [] })
    .auth(formatAuthorizationHeader(jwtToken))
    .url(url)
    .delete()
    .json()
}

export const getVersion = (): Promise<GetVersionResponse> => {
  const url = `/version?${Date.now()}`

  return externalApi({ apiType: 'local', doNotRetryStatusCodes: [] })
    .url(url)
    .get()
    .setTimeout(5000)
    .text((version) => right({ type: 'version', version }))
}

export const checkNetworkConnection = (): Promise<boolean> =>
  getVersion()
    .then((response) =>
      pipe(
        response,
        fold(
          (error) => {
            switch (error.type) {
              case 'ABORT_ERROR':
              case 'FETCH_ERROR':
                return false
              case 'API_ERROR':
              case 'AUTHENTICATION_EXPIRED_ERROR':
              case 'AUTHENTICATION_ERROR':
              case 'THROTTLE_ERROR':
              case 'WRONG_PIN_CODE':
                return true
            }
          },
          (_data) => true
        )
      )
    )
    .catch(() => false)

