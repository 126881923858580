import React from 'react'
import { OrderSimCardsButton } from '../Sim/OrderSimCardsButton'
import { SidebarSim } from '../../images/SidebarSim'
import { SidebarSimBackground } from '../../images/SidebarSimBackground'
import { colors, containerWidths } from '../../theme'

export const MenuBuySimLink = () => (
  <>
    <div
      css={`
        display: flex;
        justify-content: center;
        border-top: 0.1875rem solid ${colors.bgColor};
        margin-top: auto;
        padding: 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        text-align: center;
      `}
    >
      <p>Need more SIM cards for the family or your other devices?</p>
      <OrderSimCardsButton />
    </div>
    <div
      css={`
        display: flex;
        justify-content: center;
        z-index: 100;
        @media screen and (max-width: ${containerWidths.lg}px) {
          display: none;
        }
      `}
    >
      <div>
        <SidebarSim />
      </div>
      <div>
        <SidebarSimBackground
          css={`
            position: absolute;
            left: 0;
            bottom: 0;
          `}
        />
      </div>
    </div>
  </>
)
