import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { pipe } from 'fp-ts/es6/pipeable'
import { fold } from 'fp-ts/es6/Either'
import { useJwtContext } from '../../context/JwtContext'
import { Button } from '../Button/Button'
import { registerAccountWithSoMeApi, registerAccountWithEmailApi } from '../../api/api-auth'
import { useErrorContext } from '../../context/ErrorContext'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../../api/api-utils'
import { PageTitle } from '../styles/PageTitle'
import { GetCountryListSuccessResponse, Country } from '../../api/api-types'
import { containerWidths, colors } from '../../theme'
import { SignInWrapper } from '../SignIn/SignInWrapper'
import { SIGN_UP_BUTTON_ID } from '../../test-selectors'
import { formatPageTitle } from '../../formatPageTitle'
import { config } from '../../config'
import { ExternalLink } from '../common/ExternalLink'
import { DataLayerEvent, sendEvent } from '../../utils/analytics-utils'
import { Checkbox } from '../Checkbox/Checkbox'
import { InputField } from '../common/InputField'
import { CountrySelect } from './CountrySelect'

const FormItem = styled.div`
  padding: 1.25rem 0;
`

const Label = styled.label`
  font-size: 0.75rem;
  font-weight: 600;
`

const BottomTermsLink = styled(ExternalLink)`
  color: ${colors.hermesGreen};
  text-decoration: none;
`

interface Props {
  api: ReturnType<typeof registerAccountWithSoMeApi | typeof registerAccountWithEmailApi>
  onAccountCreated: (token?: string) => void
  countryList: GetCountryListSuccessResponse
}

export const CreateAccount = ({ api, onAccountCreated, countryList }: Props) => {
  const { t } = useTranslation()
  const [firstName, setFirstName] = useState<null | string>(null)
  const [lastName, setLastName] = useState<null | string>(null)
  const [country, setCountry] = useState<null | Country>(null)
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setJwtToken } = useJwtContext()
  const { onError } = useErrorContext()
  const dataLoader = useDataLoader(api)
  useEffect(() => {
    document.title = formatPageTitle('Create account')
  }, [])

  const onMarketingConsentChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMarketingConsent(event.target.checked)

  const sendAnalyticsOnAccountCreated = (countryCode: string) => {
    const event: DataLayerEvent = {
      event: 'GAEvent',
      eventCategory: 'click_create_account',
      eventAction: 'click_create_account',
      eventLabel: countryCode,
    }
    sendEvent(event)
  }

  const register = (countryCode: string) => {
    setIsLoading(true)
    dataLoader
      .query({
        firstName,
        lastName,
        country: countryCode,
        marketingConsent,
      })
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              setIsLoading(false)

              switch (error.type) {
                case 'ABORT_ERROR':
                case 'THROTTLE_ERROR':
                case 'WRONG_PIN_CODE':
                  return
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return renewJwtTokenOnAuthenticationExpiredError()
                case 'AUTHENTICATION_ERROR':
                  return redirectToSignInOnAuthenticationError()
                case 'FETCH_ERROR':
                case 'API_ERROR':
                case 'UNSUPPORTED_RESPONSE':
                  return onError(error, error.type)
                default:
                  return exhaustiveCheck(error)
              }
            },
            (successData) => {
              sendAnalyticsOnAccountCreated(countryCode)
              if (successData.type === 'some') {
                setJwtToken(successData.jwt.accessToken)
                onAccountCreated()
              }
              setIsLoading(false)
              if (successData.type === 'email') {
                onAccountCreated(successData.code)
              }
            }
          )
        )
      )
      .catch(onError)
  }

  const registerButtonIsDisabled = isLoading || !country

  return (
    <SignInWrapper>
      <>
        <PageTitle
          css={`
            font-size: 2rem;
            line-height: 2.375rem;
            font-weight: bold;
            margin-bottom: 1rem;
          `}
        >
          {t('createAccount.finalizeYourAccount')}
        </PageTitle>
        <FormItem>
          <p
            css={`
              font-size: 1rem;
              color: rgba(0, 30, 45, 0.6);
              font-weight: 500;
              line-height: 1.5rem;
              margin-top: 0;
              margin-bottom: 1rem;
            `}
          >
            {t('createAccount.countryHelpText')}
          </p>
          <div>
            <Label htmlFor="firstName">{t('editProfilePage.firstName')}</Label>
            <br />
            <InputField onChange={(event) => setFirstName(event.target.value)} />
            <br />
            <Label htmlFor="lastName">{t('editProfilePage.lastName')}</Label>
            <br />
            <InputField onChange={(event) => setLastName(event.target.value)} />
          </div>
          <label
            css={`
              margin-top: 0.5rem;
              display: flex;
              flex-direction: column;
              font-size: 0.75rem;
              font-weight: 600;
            `}
          >
            {t('createAccount.countryOfResidence')}
            <CountrySelect
              countryList={countryList}
              css={`
                height: 3.375rem;
                width: 100%;
                @media screen and (min-width: ${containerWidths.md}px) {
                  width: 100%;
                }
              `}
              onChange={(countryCode) => setCountry(countryCode)}
            />
          </label>
          <div
            css={`
              margin-top: 1.5rem;
              display: flex;
              justify-content: space-between;
            `}
          >
            <Checkbox checked={marketingConsent} onChange={onMarketingConsentChange} />
            <div
              css={`
                margin-left: 1rem;
                display: flex;
              `}
            >
              {t('editProfilePage.marketingConcent')}
            </div>
          </div>
        </FormItem>

        <Button
          id={SIGN_UP_BUTTON_ID}
          text={t('createAccount.createAccount')}
          disabled={registerButtonIsDisabled}
          onClick={() => {
            if (country?.twoLetterCode) {
              register(country.twoLetterCode)
            }
          }}
        />

        <div
          css={`
            color: ${colors.greyText};
            margin-top: 1.25rem;
            text-align: center;
          `}
        >
          <Trans i18nKey="terms">
            {`By continuing, you agree to HMD Global's `}
            <BottomTermsLink href={config.serviceTermsUrl}>
              <>Service Terms</>
            </BottomTermsLink>
            ,{' '}
            <BottomTermsLink href={config.privacyPolicyUrl}>
              <>Privacy Policy</>
            </BottomTermsLink>{' '}
            and HMD Mobile{' '}
            <BottomTermsLink href={config.privacySupplementUrl}>
              <>Privacy Supplement</>
            </BottomTermsLink>
          </Trans>
        </div>
      </>
    </SignInWrapper>
  )
}
