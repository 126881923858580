import React, { useContext } from 'react'
import { AppQuery } from '../api/api-types'
import { AppQueryAction } from '../types'

export function appQueryReducer(_: AppQuery | null, action: AppQueryAction): AppQuery | null {
  if (action.type === 'reset') {
    return action.appQuery
  }

  if (action.type === 'processing-order' || action.type === 'activate-subscription' || action.type === 'select-account') {
    return action.appQuery
  }

  return null
}

export interface AppQueryContext {
  appQuery: AppQuery | null
  dispatch: React.Dispatch<AppQueryAction>
  redirectToActivatePlan: (activationId: string) => void
  redirectToActivateManually: () => void
}

export interface RegAppQueryContext extends AppQueryContext {
  appQuery: AppQuery
}

export const AppQueryContext = React.createContext<AppQueryContext | null>(null)

export const useAppQueryContext = () => {
  const context = useContext(AppQueryContext)
  if (!context) {
    throw new Error('AppQuery Context is not available, Component needs to be child of AppQuery Context provider')
  }
  return context
}

export const useRegAppQueryContext = (): RegAppQueryContext => {
  const context = useContext(AppQueryContext)

  if (!context) {
    throw new Error('RegAppQuery Context is not available')
  }

  const { appQuery } = context

  if (!appQuery) {
    throw new Error('RegAppQuery Context.appQuery is not available')
  }

  return { ...context, appQuery }
}
