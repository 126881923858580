import { i18n } from '../i18n'

export const formatStringToChunks = (value: string, chunkSize: number) => {
  const regexp = new RegExp(`.{1,${chunkSize}}`, 'g')
  const chunks = value.match(regexp) || []
  return chunks.join(' ')
}

export const valueWithoutSpaces = (value: string) => value.replace(/ /g, '')

export const formatPhonenumber = (msisdn: string) => {
  if (msisdn.length !== 12) {
    return msisdn
  }

  const match = msisdn.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`
  }
  return msisdn
}

export const formatPrice = (price: number | undefined | null, currency: string | null | undefined) => {
  if (price === null || price === undefined || currency === null || currency === undefined) {
    return `-`
  }
  return Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency ?? 'EUR' }).format(price ?? 0)
}
