import React, { useEffect, Suspense } from 'react'
import { pipe } from 'fp-ts/es6/pipeable'
import { fold } from 'fp-ts/es6/Either'
import { JwtTokenProps } from './types'
import { postAppQuery } from './api/api'
import { LoadingPage } from './components/Loading/LoadingPage'
import { useDataLoader } from './customHooks/useDataLoader'
import { exhaustiveCheck } from './exhaustive-switch-check'
import { useErrorContext } from './context/ErrorContext'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from './api/api-utils'
import { useAppQueryContext } from './context/AppQueryContext'

const AuthenticatedUserRoutes = React.lazy(() => import(/* webpackPrefetch: true */ './AuthenticatedUserRoutes'))

type Props = JwtTokenProps

export const AppQueryLoader = ({ jwtToken }: Props) => {
  const { onError } = useErrorContext()
  const { appQuery, redirectToActivatePlan, redirectToActivateManually, dispatch } = useAppQueryContext()
  const { query } = useDataLoader(postAppQuery)

  useEffect(() => {
    query({ jwtToken })
      .then((response) =>
        pipe(
          response,
          fold(
            (error): void => {
              switch (error.type) {
                case 'THROTTLE_ERROR':
                case 'WRONG_PIN_CODE':
                  return
                case 'FETCH_ERROR':
                case 'API_ERROR':
                case 'UNSUPPORTED_RESPONSE':
                  return onError(error, error.type)
                case 'ABORT_ERROR':
                  return
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return renewJwtTokenOnAuthenticationExpiredError()
                case 'AUTHENTICATION_ERROR':
                  return redirectToSignInOnAuthenticationError()
                default:
                  return exhaustiveCheck(error)
              }
            },
            (data) => {
              switch (data.type) {
                case 'full-response':
                  if (data.appQuery.recentOrders !== undefined) {
                    return redirectToActivateManually()
                  }
                  if (data.appQuery.pendingActivation !== undefined) {
                    return redirectToActivatePlan(data.appQuery.pendingActivation)
                  }
                  return dispatch({ type: 'reset', appQuery: data.appQuery })
              }
            }
          )
        )
      )
      .catch(onError)
  }, [dispatch, jwtToken, onError, query, redirectToActivatePlan, redirectToActivateManually])

  if (!appQuery) {
    return <LoadingPage />
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <AuthenticatedUserRoutes jwtToken={jwtToken} appQuery={appQuery} />
    </Suspense>
  )
}
