import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import { SignInWrapper } from '../components/SignIn/SignInWrapper'
import { formatPageTitle } from '../formatPageTitle'
import { GreenButton } from '../components/Button/Button'
import { urls } from '../urls'
import { ACTIVATE_PLAN_ID } from '../test-selectors'
import { colors } from '../theme'
import { MobileIcon } from '../icons/MobileIcon'
import { postActivatePendingSubscription } from '../api/api'
import { useDataLoader } from '../customHooks/useDataLoader'
import { JwtTokenProps } from '../types'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../api/api-utils'
import { exhaustiveCheck } from '../exhaustive-switch-check'
import { useErrorContext } from '../context/ErrorContext'
import { useAppQueryContext } from '../context/AppQueryContext'

interface Props extends JwtTokenProps {
  activationId: string
}

const Content = styled.p`
  margin: 0;
  padding: 1rem 2rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(0, 30, 45, 0.6);
  > b {
    font-weight: 700;
    color: ${colors.hermesGreen};
  }
`

interface PageContentProps {
  title: string
  buttonText: string
  content: React.ReactElement
  onButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isLoading?: boolean | undefined
}

const PageContent = ({ title, buttonText, content, onButtonClick, isLoading }: PageContentProps) => (
  <>
    <div
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        background-color: ${colors.hermesGreenOpaPointOne};
      `}
    >
      <MobileIcon color={colors.hermesGreen} />
    </div>
    <h3
      css={`
        font-size: 2rem;
        line-height: 2.375rem;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
        font-weight: bold;
      `}
    >
      {title}
    </h3>
    {content}
    <div
      css={`
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        item-align: center;
      `}
    >
      <GreenButton
        css={`
          padding: 1rem 3rem;
        `}
        id={ACTIVATE_PLAN_ID}
        loading={isLoading ?? false}
        text={buttonText}
        onClick={(e) => onButtonClick(e)}
      />
    </div>
  </>
)

export const ActivatePlanRoute = ({ jwtToken, activationId }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { onError } = useErrorContext()
  const { dispatch } = useAppQueryContext()

  const api = useDataLoader(postActivatePendingSubscription)
  const { query, isLoading } = api
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    document.title = formatPageTitle('Activate SIM card')
  }, [t])

  return (
    <SignInWrapper>
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        {!completed ? (
          <PageContent
            title={'Good news!'}
            content={
              <>
                <Content>
                  Your SIM card is already linked to your account. Just press the button below to activate it.
                </Content>
                <Content>
                  Please note that activating your SIM card will start your chosen mobile plan, so you{' '}
                  <b> should only activate your SIM card once it has arrived.</b>
                </Content>
              </>
            }
            isLoading={isLoading}
            buttonText={'Activate SIM card'}
            onButtonClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault()
              query({
                jwtToken,
                body: {
                  activationId,
                },
              })
                .then((response) =>
                  pipe(
                    response,
                    fold(
                      (error) => {
                        switch (error.type) {
                          case 'THROTTLE_ERROR':
                          case 'WRONG_PIN_CODE':
                            return
                          case 'FETCH_ERROR':
                          case 'API_ERROR':
                          case 'UNSUPPORTED_RESPONSE':
                            return onError(error, error.type)
                          case 'ABORT_ERROR':
                            return
                          case 'AUTHENTICATION_EXPIRED_ERROR':
                            return renewJwtTokenOnAuthenticationExpiredError()
                          case 'AUTHENTICATION_ERROR':
                            return redirectToSignInOnAuthenticationError()
                          default:
                            return exhaustiveCheck(error)
                        }
                      },
                      (appQueryData) => {
                        switch (appQueryData.type) {
                          case 'full-response':
                            dispatch({ type: 'activate-subscription', appQuery: appQueryData.appQuery })
                            setCompleted(true)
                        }
                      }
                    )
                  )
                )
                .catch(onError)
            }}
          />
        ) : (
          <PageContent
            title={'Activation successful'}
            content={
              <>
                <Content>
                  All done. If your SIM card is already installed, you should be able to start using your SIM right
                  away.
                </Content>
                <Content>
                  If you have not installed your card yet, it is safest to power off your device before opening the SIM
                  tray.
                </Content>
              </>
            }
            buttonText={'Continue'}
            onButtonClick={() => {
              history.push(urls.home)
            }}
          />
        )}
      </div>
    </SignInWrapper>
  )
}
