import React from 'react'
import { Icon } from '../types'

export const FacebookIcon = (props: Icon) => (
  <svg className={props.className} width={'24px'} height={'24px'} viewBox="0 0 24 24">
    <title>Facebook</title>
    <path
      fill="#001E2D"
      d="M22 12a10 10 0 10-11.56 9.88v-6.99H7.9V12h2.54V9.8c0-2.5 1.49-3.9 3.77-3.9 1.1 0 2.24.2 2.24.2v2.46H15.2c-1.24 0-1.63.77-1.63 1.56V12h2.78l-.45 2.9h-2.33v6.98A10 10 0 0022 12"
    />
  </svg>
)
