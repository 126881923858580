import React from 'react'
import { IconWithColor } from '../types'

export const TruckIcon = (props: IconWithColor) => (
  <svg className={props.className} width="34px" height="24px" viewBox="0 0 34 24">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-294.000000, -167.000000)">
        <g transform="translate(281.000000, 149.000000)">
          <g transform="translate(12.000000, 12.000000)">
            <g>
              <polygon id="Path" points="0 0 36 0 36 36 0 36" color={props.color} />
              <path
                d="M30,12 L25.5,12 L25.5,6 L4.5,6 C2.85,6 1.5,7.35 1.5,9 L1.5,25.5 L4.5,25.5 C4.5,27.99 6.51,30 9,30 C11.49,30 13.5,27.99 13.5,25.5 L22.5,25.5 C22.5,27.99 24.51,30 27,30 C29.49,30 31.5,27.99 31.5,25.5 L34.5,25.5 L34.5,18 L30,12 Z M9,27.75 C7.755,27.75 6.75,26.745 6.75,25.5 C6.75,24.255 7.755,23.25 9,23.25 C10.245,23.25 11.25,24.255 11.25,25.5 C11.25,26.745 10.245,27.75 9,27.75 Z M29.25,14.25 L32.19,18 L25.5,18 L25.5,14.25 L29.25,14.25 Z M27,27.75 C25.755,27.75 24.75,26.745 24.75,25.5 C24.75,24.255 25.755,23.25 27,23.25 C28.245,23.25 29.25,24.255 29.25,25.5 C29.25,26.745 28.245,27.75 27,27.75 Z"
                fill={props.color}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
