import React from 'react'
import { IconWithColor } from '../types'

export interface IconWithColorAndFlip extends IconWithColor {
  color: string
  fliped: boolean
}

export const DownArrow = (props: IconWithColorAndFlip) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={props.fliped ? 'rotate(180)' : 'rotate(0)'}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.41 0.590027L6 5.17003L10.59 0.590027L12 2.00003L6 8.00003L0 2.00003L1.41 0.590027Z"
      fill={props.color}
    />
  </svg>
)
