import ReactDOM from 'react-dom'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Normalize } from 'styled-normalize'
import './i18n'
import { App } from './App'
import { ErrorBoundary } from './ErrorBoundary'
import { ScrollToTop } from './components/common/ScrollTop'
import { GlobalStyle } from './components/common/GlobalStyle'

const $root = document.getElementById('root')
if (!$root) {
  throw new Error('#root is missing')
}

async function loadPolyfills() {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

loadPolyfills().catch((_error) => {})

// index.html has global error handler for cases when the app can't start at all
window.onerror = null
ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
      <ScrollToTop />
      <Normalize />
      <GlobalStyle />
      <App />
    </ErrorBoundary>
  </BrowserRouter>,
  $root
)
