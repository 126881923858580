import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import * as queryString from 'query-string'
import { formatPageTitle } from '../../formatPageTitle'
import { urls } from '../../urls'
import { PinActivation } from './PinActivation'

export const EmailPinCodeLoginRoute = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { email, code } = queryString.parse(location.search)
  useEffect(() => {
    document.title = formatPageTitle(t('emailPinCodeLoginRoute.pageTitle'))
  }, [t])

  if (typeof email !== 'string' || typeof code !== 'string') {
    return <Redirect to={urls.signIn} />
  }

  return (
    <PinActivation
      onPinToken={(token: string) => history.replace(urls.enterEmailLoginPinCode.clientUrl(email, token))}
      magicLinkResendToken={code}
      email={email}
    />
  )
}
