import { left, right, fold, Either } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import { config } from '../config'
import { externalApi, onApiJsonValidationError } from './api-utils'
import { ContentfulLocalisationResponse, UnsupportedResponse } from './api-types'

export const getLocalisations = async (
  locale: string
): Promise<Either<UnsupportedResponse, ContentfulLocalisationResponse>> => {
  const url = `/`
  const query = `query($preview: Boolean, $locale: String) {
    appContentCollection(preview: $preview, locale: $locale, where: { appId: "web" }, limit: 1) {
        items {
            sys {
                publishedVersion
            },
            keyValuePairsCollection(limit: 1000) {
                items {
                    key,
                    value {
                        ... on Heading {
                            string: heading
                        },
                        ... on Paragraph {
                            string: paragraph
                        }
                    }
                }
            }
        }
    }
}`

  const usePreviewVersions = config.contentfulPreview
  const variables = { preview: usePreviewVersions, locale }
  const body = { query, variables }

  return externalApi({ apiType: 'contentful-graphql', doNotRetryStatusCodes: [401] })
    .url(url)
    .post(body)
    .error(401, (_error) => left({ type: 'error', message: 'Contentful authentication failed' }))
    .json((json) =>
      pipe(
        ContentfulLocalisationResponse.decode(json),
        fold(onApiJsonValidationError, (data) => right(data))
      )
    )
}
