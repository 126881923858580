import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import throttle from 'lodash.throttle'
import { containerWidths, colors } from '../../theme'
import { ResponsivePadding } from '../common/styles'
import { Picture } from '../common/Picture'
import TutorialPage1 from '../../images/tutorial-page1.png'
import TutorialPage2 from '../../images/tutorial-page2.png'
import TutorialPage3 from '../../images/tutorial-page3.png'
import TutorialPage4 from '../../images/tutorial-page4.png'
import { LeftArrowIcon } from '../../icons/LeftArrowIcon'
import { IconButton } from '../Button/Button'
import TutorialBackground from '../../images/tutorial_background.png'

const RightArrowIcon = styled(LeftArrowIcon)`
  margin-left: 1rem;
  transform: rotate(180deg);
`

const Control = styled.div<{ active: boolean }>`
  background-color: ${(props) => (props.active ? colors.hermesGreen : '#D2EAFC')};
  transition: 0.3s;
  cursor: pointer;

  margin-right: 0.3125rem;
  border-radius: 0.625rem;
  padding: 0.625rem;
  height: 1rem;
  width: ${(props) => (props.active ? '3rem' : '1rem')};
  @media screen and (min-width: ${containerWidths.md}px) {
    padding: 0.9375rem;
    border-radius: 0.9375rem;
    margin-right: 0.625rem;
  }
`

const Page = styled.div`
  ${ResponsivePadding};
  scroll-snap-align: start;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4.375rem;
  margin-right: 3.125rem;

  @media screen and (min-width: ${containerWidths.md}px) {
    font-size: 1.125rem;
  }
  @media screen and (min-width: ${containerWidths.lg}px) {
    font-size: 1.375rem;
  }
`

const Pages = styled.div`
  overflow-x: scroll;
  display: flex;
  align-items: baseline;
  scroll-snap-type: x mandatory;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const Controls = styled.div`
  display: flex;
  ${ResponsivePadding};
  align-items: center;
  justify-content: space-between;
`

const PageTitle = styled.h1`
  color: ${colors.hermesGreen};
  font-size: 2rem;
  font-weight: bold;
`

const Content = styled.div`
  color: ${colors.darkGreyOpaPointSix};
  max-width: 80%;
`

const ImageContainer = ({ image }: { image: string }) => (
  <div
    css={`
      margin: auto;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      @media screen and (min-width: ${containerWidths.md}px) {
        width: 80%;
      }
    `}
  >
    <Picture
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        margin-left: 2.5rem;
        img {
          height: 25rem;
        }
      `}
      src={image}
    />
  </div>
)

const pageIdPrefix = 'tutorial-page-'
const pageId = (index: number) => `${pageIdPrefix}${index}`
const getIndexFromPageId = (id: string) => parseInt(id.replace(pageIdPrefix, ''), 10)
const getPageById = (index: number) => document.getElementById(pageId(index))
const pageClassName = 'tutorial-page'

export const TutorialSection = () => {
  const [activeIndex, setIndex] = useState(0)
  const pagesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = pagesRef.current
    if (!el) {
      return
    }

    const onScroll = () => {
      if (!pagesRef.current) {
        return
      }

      // calculate the intersection of the visible area and the available pages, the one with the highest visibility
      // percentage is the active page
      const parentX = pagesRef.current.getBoundingClientRect().x
      const pages = Array.from(pagesRef.current.querySelectorAll(`.${pageClassName}`))
        .map((p) => {
          const rect = p.getBoundingClientRect()
          const pageWidth = rect.width
          return {
            boundingClientRect: p.getBoundingClientRect(),
            index: getIndexFromPageId(p.id),
            percentage: (pageWidth - Math.abs(pageWidth - (rect.x - parentX + pageWidth))) / pageWidth,
          }
        })
        .sort((a, b) => {
          if (a.percentage < b.percentage) {
            return -1
          }
          if (a.percentage > b.percentage) {
            return 1
          }
          return 0
        })
        .reverse()

      const activeIndex = pages[0]?.index

      if (typeof activeIndex === 'number') {
        setIndex(activeIndex)
      }
    }
    const throttled = throttle(onScroll, 250, { trailing: true, leading: true })

    el.addEventListener('scroll', throttled)

    return () => {
      el.removeEventListener('scroll', throttled)
    }
  }, [])

  const pages = [
    <>
      <ImageContainer image={TutorialPage1} />
      <PageTitle>{'Stay in control'}</PageTitle>
      <Content>
        {'The HMD Mobile app puts you in the driver’s seat. Track your data consumption, change your plan and more.'}
      </Content>
    </>,
    <>
      <ImageContainer image={TutorialPage2} />
      <PageTitle>{'Keep an eye on things'}</PageTitle>
      <Content>
        {'A quick glance will tell you how much of your mobile data you’ve used for the current renewal cycle.'}
      </Content>
    </>,
    <>
      <ImageContainer image={TutorialPage3} />
      <PageTitle>{'Change of plan? No problem'}</PageTitle>
      <Content>
        {
          'If you need more or less data for your next renewal cycle, simply choose a different plan from within the app.'
        }
      </Content>
    </>,
    <>
      <ImageContainer image={TutorialPage4} />
      <PageTitle>{'Got your SIM card?'}</PageTitle>
      <Content>
        {
          'If you still need to install your SIM card, remember to power off your device before opening the SIM tray. Otherwise, let’s get started!'
        }
      </Content>
    </>,
  ]

  return (
    <div
      css={`
        height: 100%;
        position: relative;
        padding: 0;
        background-image: url(${TutorialBackground});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
        @media screen and (min-width: ${containerWidths.md}px) {
          padding: 0;
        }
      `}
    >
      <Pages ref={pagesRef}>
        {pages.map((page, index) => (
          <Page className={pageClassName} id={`${pageId(index)}`} key={index}>
            <div
              css={`
                min-height: 12.5rem;
              `}
            >
              {page}
            </div>
          </Page>
        ))}
      </Pages>

      <Controls>
        <div
          css={`
            display: flex;
          `}
        >
          {pages.map((_page, index) => (
            <Control
              active={index === activeIndex}
              key={index}
              onClick={() => {
                const el = getPageById(index)
                if (el) {
                  el.scrollIntoView({ behavior: 'smooth' })
                }
              }}
            />
          ))}
        </div>
        <div>
          <IconButton
            disabled={activeIndex <= 0}
            onClick={() => {
              const el = getPageById(activeIndex - 1)
              if (el) {
                el.scrollIntoView({ behavior: 'smooth' })
              }
            }}
            icon={<LeftArrowIcon color={colors.hermesGreen} />}
          />
          <IconButton
            disabled={activeIndex >= pages.length - 1}
            onClick={() => {
              const el = getPageById(activeIndex + 1)
              if (el) {
                el.scrollIntoView({ behavior: activeIndex < 2 ? 'smooth' : 'auto' })
              }
            }}
            icon={<RightArrowIcon color={colors.hermesGreen} />}
          />
        </div>
      </Controls>
    </div>
  )
}
