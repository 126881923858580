import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import { ButtonText } from './ButtonText'
import { StyledBaseButton } from './StyledBaseButton'

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean
  text: string | JSX.Element
}

interface IconProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon: JSX.Element
}

export const StyledButton = styled(StyledBaseButton)<{ isloading?: boolean }>`
  min-height: 2.5625rem;
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  color: #fff;
  background-color: ${colors.hermesGreen};
  vertical-align: middle;

  :disabled {
    background-color: ${colors.concrete};
    color: #a8b2b7;
    border: 0.0625rem solid #dedede;
  }
`

export const Button = (props: Props) => {
  const { text, loading, disabled, ref, ...rest } = props

  return (
    <StyledButton as="button" isloading={loading} disabled={disabled || loading} {...rest}>
      <ButtonText isLoading={loading} text={text} />
    </StyledButton>
  )
}

export const IconButton = (props: IconProps) => {
  const { icon, disabled, ref, ...rest } = props

  return (
    <StyledBaseButton
      css={`
        background-color: transparent;
      `}
      as="button"
      disabled={disabled}
      {...rest}
    >
      {icon}
    </StyledBaseButton>
  )
}

export const CancelButton = styled(Button)`
  background-color: #fff;
  border: 0.0625rem solid #e6e9ea;
  color: ${colors.hermesGreen};
  &:hover {
    opacity: 1;
    background-color: #e6e9ea;
  }
`

export const TextButton = styled(Button)`
  background-color: transparent;
  font-size: 0.875rem;
  padding: 0.3125rem 0;
  :disabled {
    color: ${colors.greyText};
    background-color: transparent;
    border: transparent;
  }
`

export const GreenButton = styled(Button)`
  background-color: ${colors.hermesGreen};
`

export const WarningButton = styled(Button)`
  background-color: ${colors.warningRed};
`

export const ErrorButton = styled(Button)`
  background-color: ${colors.errorRed};
`

export const GreenTextButton = styled(TextButton)`
  color: ${colors.hermesGreen};
`

export const WarningTextButton = styled(TextButton)`
  color: ${colors.warningRed};
`
