import { useEffect, useState } from 'react'
import * as queryString from 'query-string'
import { useLocation, Redirect } from 'react-router'
import React from 'react'
import { pipe } from 'fp-ts/es6/pipeable'
import { fold } from 'fp-ts/es6/Either'
import { urls } from '../urls'
import { useJwtContext } from '../context/JwtContext'
import { reportError } from '../utils/error-utils'
import { getJwtToken } from '../api/api-auth'
import { exhaustiveCheck } from '../exhaustive-switch-check'
import { useErrorContext } from '../context/ErrorContext'
import { redirectToSignInOnAuthenticationError, renewJwtTokenOnAuthenticationExpiredError } from '../api/api-utils'
import { useDataLoader } from '../customHooks/useDataLoader'
import { LoadingPage } from './Loading/LoadingPage'

type RedirectTarget = { type: 'not-set' } | { type: 'ready'; target: string }

export const RegisteredUserRedirect = () => {
  const [redirectTarget, setRedirectTarget] = useState<RedirectTarget>({ type: 'not-set' })
  const location = useLocation()
  const { setJwtToken } = useJwtContext()
  const { setJwtRefreshToken } = useJwtContext()
  const { query } = useDataLoader(getJwtToken)
  const { onError } = useErrorContext()

  useEffect(() => {
    const { t } = queryString.parse(location.search)
    if (typeof t !== 'string') {
      alert('Unexpected Error, you are now redirected back to signin')
      reportError(new Error('missing token in registered user redirect'))
      return setRedirectTarget({ type: 'ready', target: urls.signIn })
    }

    query(t)
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              switch (error.type) {
                case 'FETCH_ERROR':
                case 'API_ERROR':
                case 'UNSUPPORTED_RESPONSE':
                  return onError(error, error.type)
                case 'ABORT_ERROR':
                case 'THROTTLE_ERROR':
                case 'WRONG_PIN_CODE':
                  return
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return renewJwtTokenOnAuthenticationExpiredError()
                case 'AUTHENTICATION_ERROR':
                  return redirectToSignInOnAuthenticationError()
                default:
                  return exhaustiveCheck(error)
              }
            },
            (data) => {
              setJwtToken(data.accessToken)
              setJwtRefreshToken(data.refreshToken)
              setRedirectTarget({ type: 'ready', target: urls.home })
            }
          )
        )
      )
      .catch(onError)
  }, [location.search, onError, query, setJwtToken, setJwtRefreshToken])

  switch (redirectTarget.type) {
    case 'not-set':
      return <LoadingPage />
    case 'ready':
      return <Redirect to={redirectTarget.target} />
    default:
      return exhaustiveCheck(redirectTarget)
  }
}
