import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { pipe } from 'fp-ts/es6/pipeable'
import { fold } from 'fp-ts/es6/Either'
import { useHistory } from 'react-router'
import { TextButton } from '../Button/Button'
import { putResendMagicLink } from '../../api/api-auth'
import { useErrorContext } from '../../context/ErrorContext'
import { exhaustiveCheck } from '../../exhaustive-switch-check'
import { useDataLoader } from '../../customHooks/useDataLoader'
import { PageTitle } from '../styles/PageTitle'
import { formatPageTitle } from '../../formatPageTitle'
import { postValidateMagicLink } from '../../api/api-auth'
import { colors } from '../../theme'
import { renewJwtTokenOnAuthenticationExpiredError, redirectToSignInOnAuthenticationError } from '../../api/api-utils'
import { config } from '../../config'
import { urls } from '../../urls'
import { useJwtContext } from '../../context/JwtContext'
import { ENTER_ACCESS_CODE_ID, ACCESS_CODE_FORM_ID, ACCESS_CODE_FORM_INPUT_CLASS } from '../../test-selectors'
import { SignInWrapper } from './SignInWrapper'

const FormItem = styled.div`
  padding: 1.25rem 0;
`

const PinInput = styled.input`
  font-size: 1.5rem;
  font-weight: 600;
  width: 3.375rem;
  height: 4.5rem;
  margin: 0.625rem;
  text-align: center;
  border-radius: 0.5rem;
  border: solid 0.125rem ${colors.hermesGrey};
  :focus {
    outline: none;
    border-color: ${colors.darkGrey};
  }
`

interface Props {
  onPinToken: (token: any) => void
  magicLinkResendToken: string
  email: string
}

export const PinActivation = ({ onPinToken, magicLinkResendToken, email }: Props) => {
  const pinItems = [1, 2, 3, 4]
  const [pin, setPin] = useState(new Array(pinItems.length).fill(''))
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()

  const { setJwtToken } = useJwtContext()

  useEffect(() => {
    document.title = formatPageTitle(t('pinActivation.pageTitle'))
  }, [t])

  const updatePinField = (value: any, index: number): string[] => {
    const pinDigits = [...pin]
    pinDigits[index] = value
    setPin(pinDigits)
    return pinDigits
  }

  const handlePinInputChange = (event: any, index: number) => {
    const form = event.target.form
    // const index = Array.prototype.indexOf.call(form, event.target)
    const pinDigits = updatePinField(event.target.value, index)
    if (event.target.value !== '' && form.elements[index + 1]) {
      form.elements[index + 1].focus()
      event.preventDefault()
    }
    submitPinIfFilled(pinDigits)
  }

  const submitPinIfFilled = (pinDigits: string[]) => {
    const pinCodeHasBeenFilled = pinDigits.every((n) => n !== '' && /^\d$/.test(n))
    if (pinCodeHasBeenFilled) {
      const pinString: string = pinDigits.join('')
      submitPin(pinString, magicLinkResendToken)
    }
  }

  const handleBackspace = (event: any, index: number) => {
    const form = event.target.form
    updatePinField(event.target.value, index)
    if (event.keyCode === 8 && event.target.value === '' && form.elements[index - 1]) {
      form.elements[index - 1].focus()
    }
  }

  const api = useDataLoader(putResendMagicLink)
  const validatePinApi = useDataLoader(postValidateMagicLink)
  const { onError } = useErrorContext()

  const submitPin = (magicLinkValidationCode: string, magicLinkResendToken: string) => {
    const requestData = {
      magicLinkResendToken,
      magicLinkValidationCode,
      apiKey: config.emailLoginApiKey,
    }

    validatePinApi
      .query(requestData)
      .then((response) =>
        pipe(
          response,
          fold(
            (error) => {
              switch (error.type) {
                case 'WRONG_PIN_CODE':
                  return setErrorMessage(t('pinActivation.invalidCode'))
                case 'FETCH_ERROR':
                case 'API_ERROR':
                case 'UNSUPPORTED_RESPONSE':
                  return onError(error, error.type)
                case 'ABORT_ERROR':
                  return
                case 'THROTTLE_ERROR':
                  return setErrorMessage(t('pinActivation.throttleError'))
                case 'AUTHENTICATION_EXPIRED_ERROR':
                  return onError(new Error('email magiclink login authentication renewal error'))
                case 'AUTHENTICATION_ERROR':
                  return onError(new Error('email magiclink login authentication error'))
                default:
                  return exhaustiveCheck(error)
              }
            },
            (data) => {
              setJwtToken(data.accessToken)
              history.push(urls.home)
            }
          )
        )
      )
      .catch(onError)
  }

  return (
    <SignInWrapper>
      <>
        <PageTitle
          id={ENTER_ACCESS_CODE_ID}
          css={`
            font-size: 2rem;
            font-weight: bold;
            line-height: 2.375rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
          `}
        >
          {t('pinActivation.signInPageTitle')}
        </PageTitle>
        <FormItem id={ACCESS_CODE_FORM_ID}>
          <p
            css={`
              font-size: 1rem;
              line-height: 1.5rem;
              color: rgba(0, 30, 45, 0.6);
              text-align: center;
            `}
          >
            {t('pinActivation.helpText', { emailAddress: email })}
          </p>

          <form
            css={`
              margin-top: 3.125rem;
              display: flex;
              justify-content: center;
            `}
          >
            {pinItems.map((i) => (
              <PinInput
                key={i}
                type={'tel'}
                autoFocus={i === 1}
                className={ACCESS_CODE_FORM_INPUT_CLASS}
                maxLength={1}
                onChange={(event) => handlePinInputChange(event, i - 1)}
                onKeyDown={(event) => handleBackspace(event, i - 1)}
              />
            ))}
          </form>
        </FormItem>

        <div
          css={`
            color: ${colors.greyText};
            text-align: center;
          `}
        >
          {errorMessage && (
            <div>
              <p
                css={`
                  color: rgb(239, 78, 82);
                  font-size: 0.75rem;
                  line-height: 0.75rem;
                `}
              >
                {errorMessage}
              </p>
            </div>
          )}
          <TextButton
            css={`
              color: ${colors.hermesGreen};
            `}
            text={t('pinActivation.resendCode')}
            onClick={async () => {
              api
                .query({ apiKey: config.emailLoginApiKey, token: magicLinkResendToken, email })
                .then((response) =>
                  pipe(
                    response,
                    fold(
                      (error) => {
                        switch (error.type) {
                          case 'THROTTLE_ERROR':
                            return setErrorMessage(t('pinActivation.throttleError'))
                          case 'ABORT_ERROR':
                            return
                          case 'WRONG_PIN_CODE':
                            return
                          case 'FETCH_ERROR':
                          case 'API_ERROR':
                          case 'UNSUPPORTED_RESPONSE':
                            return onError(error, error.type)

                          case 'AUTHENTICATION_EXPIRED_ERROR':
                            return renewJwtTokenOnAuthenticationExpiredError()
                          case 'AUTHENTICATION_ERROR':
                            return redirectToSignInOnAuthenticationError()
                          default:
                            return exhaustiveCheck(error)
                        }
                      },
                      (data) => {
                        onPinToken(data.code)
                      }
                    )
                  )
                )
                .catch(onError)
            }}
          />
        </div>
      </>
    </SignInWrapper>
  )
}
