import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExternalLink } from '../common/ExternalLink'
import { colors } from '../../theme'
import { config } from '../../config'

const Link = styled(ExternalLink)`
  border-radius: 2rem;
  padding: 0.625rem 0.9375rem;
  color: ${colors.HMDBlue};
  background-color: #fff;
  border: 2px solid ${colors.hermesGreen};
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
`

export const OrderSimCardsButton = () => {
  const { t } = useTranslation()
  return (
    <Link href={config.checkoutUrl}>
      <>{t('orderSimCardsButton.orderNow')}</>
    </Link>
  )
}
