import React from 'react'
import { useLocation, Redirect, useHistory } from 'react-router'
import * as queryString from 'query-string'
import { registerAccountWithSoMeApi } from '../../api/api-auth'
import { urls } from '../../urls'
import { LoadingPage } from '../Loading/LoadingPage'
import { useCountryListLoader } from '../../customHooks/useCountryListLoader'
import { CreateAccount } from './CreateAccount'

export const SoMeCreateAccountRoute = () => {
  const location = useLocation()
  const history = useHistory()
  const { t } = queryString.parse(location.search)
  const countryList = useCountryListLoader()

  if (typeof t !== 'string') {
    return <Redirect to={urls.signIn} />
  }

  if (!countryList) {
    return <LoadingPage />
  }

  const api = registerAccountWithSoMeApi(t)

  return <CreateAccount countryList={countryList} api={api} onAccountCreated={() => history.push(urls.home)} />
}
