export const idSelector = (idName: string) => `#${idName}`
export const classSelector = (className: string) => `.${className}`
export const selectorIsVisibleOption = { visible: true }
export const waitUntilReady = { waitUntil: 'networkidle2' as const }

export function delay(time: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time)
  })
}

export const clearInputValue = async (inputSelector: string) => {
  const inputValue = await page.$eval(inputSelector, (el) => (el as HTMLInputElement).value)
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < inputValue.length; i++) {
    await page.keyboard.press('Backspace')
  }
}

export const waitAndClickButton = async (buttonId: string) => {
  await page.waitForSelector(idSelector(buttonId), selectorIsVisibleOption)
  await page.click(idSelector(buttonId))
}

export const testBaseUrl = process.env.BASE_URL || 'http://dev-hermes.azureedge.net'
export const componentTestEnv = process.env.HERMES_TEST || false

export const assertElementIsDisabled = async (selector: string) => {
  const disabledElement = await page.$(`${selector}:disabled`)
  expect(disabledElement).toBeDefined()
}
