const parseBooleanString = (value?: string) => value === 'true'

export const config = {
  apiBaseUrl: process.env.API_BASE_URL as string,
  hermesApiKey: process.env.HERMES_API_KEY as string,
  authApiBaseUrl: process.env.AUTH_API_BASE_URL as string,
  appInsightsKey: process.env.APP_INSIGHTS_KEY as string,
  appVersion: process.env.APP_VERSION as string,
  nodeEnv: process.env.NODE_ENV as string,
  serviceTermsUrl: 'https://www.hmdglobal.com/hmdmobile-service-terms',
  privacyPolicyUrl: 'https://www.nokia.com/phones/en_gb/privacyportal',
  privacySupplementUrl: 'https://www.nokia.com/phones/en_gb/privacyportal#hmd-mobile-supplement',
  faqUrl: 'https://www.hmdglobal.com/hmdmobile#faq',
  checkoutUrl: process.env.CHECKOUT_URL as string,
  contentfulGraphQlUrl: 'https://graphql.contentful.com/content/v1/spaces/139shpeoio1v',
  contentfulApiKey: process.env.CONTENTFUL_API_KEY as string,
  contentfulPreview: parseBooleanString(process.env.PREVIEW_CONTENTFUL_CONTENT),
  enableSimulateDataUsageButton: parseBooleanString(process.env.ENABLE_SIMULATE_DATA_USAGE_BUTTON),
  enableDeleteAccountButton: parseBooleanString(process.env.ENABLE_DELETE_ACCOUNT_BUTTON),
  emailLoginApiKey: process.env.EMAIL_LOGIN_API_KEY as string,
  useApiProxy: parseBooleanString(process.env.USE_API_PROXY),
  serviceWorkerDisabled: parseBooleanString(process.env.DISABLE_SW),
  showNonProductionEnvWarning: parseBooleanString(process.env.IS_NON_PROD_ENV),
  freshdeskEmailTicketApiBaseUrl: process.env.FRESHDESK_EMAIL_API_BASE_URL as string,
  freshdeskEmailTicketApiKey: process.env.FRESHDESK_EMAIL_API_KEY as string,
  freshchatToken: process.env.FRESHCHAT_TOKEN as string,
  freshchatHost: process.env.FRESHCHAT_HOST as string,
}
