import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Page, PageContent } from '../common/Page'
import { containerWidths } from '../../theme'
import { LogoIcon } from '../../icons/LogoIcon'
import { ResponsivePadding } from '../common/styles'
import { urls } from '../../urls'
import { TutorialSection } from './TutorialSection'

const Logo = styled(LogoIcon)`
  width: 9.375rem;
  @media screen and (min-width: ${containerWidths.md}px) {
    margin-bottom: 3.125rem;
  }
`

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  ${ResponsivePadding};
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Col = styled.div``

interface Props {
  children: React.ReactElement
}

export const SignInWrapper = ({ children }: Props) => (
  <Page
    menu={false}
    footer={false}
    disableGrid={true}
    css={`
      ${PageContent} {
        max-width: 100%;
        background: inherit;
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        min-height: unset;
        max-height: unset;
        margin-left: auto;
        margin-right: auto;
        height: unset;
        @media screen and (min-width: ${containerWidths.lg}px) {
          flex-direction: row;
          height: 100vh;
          ${Col} {
            width: 50%;
          }
        }
      }
    `}
  >
    <Col
      css={`
        flex: 1;
        background-color: #fff;
      `}
    >
      <SignInContainer>
        <TopContainer>
          <div
            css={`
              display: inline-block;
              margin-bottom: 2.5rem;
            `}
          >
            <Link
              title={'HMD Mobile'}
              css={`
                text-decoration: none;
                outline: none;
              `}
              to={urls.home}
            >
              <Logo />
            </Link>
          </div>
        </TopContainer>
        {children}
      </SignInContainer>
    </Col>
    <Col
      css={`
        background-color: #f3f5f8;
      `}
    >
      <TutorialSection />
    </Col>
  </Page>
)
