import React from 'react'
import styled, { css } from 'styled-components'
import { containerWidths, colors } from '../../theme'
import { Footer } from '../Footer/Footer'
import { Menu, desktopMenuWidth } from '../Menu/Menu'

export const PageContainer = styled.div`
  min-height: unset;
  display: flex;
  flex-direction: column;
  background-color: ${colors.bgColor};

  @media screen and (min-width: ${containerWidths.lg}px) {
    flex-direction: row;
  }
`

interface Props {
  children?: React.ReactNode
  className?: string
  menu: boolean
  footer: boolean
  fullWidthContent?: JSX.Element
  centered?: boolean
  id?: string
  disableGrid?: boolean
}

export const PagePaddingStyle = css`
  max-width: 760px;
  padding: 0.625rem;
  @media screen and (min-width: ${containerWidths.md}px) {
    padding: 0.9375rem;
  }
  @media screen and (min-width: ${containerWidths.lg}px) {
    padding: 1.25rem;
  }
`

export const PageContentCentered = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`

interface PageContentProps {
  disableGrid?: boolean
}

export const PageContent = styled.section`
  margin-left: 7.5vw;
  margin-right: 7.5vw;
  min-height: calc(100vh - 8.75rem);
  padding-top: 1.5rem;
  padding-bottom: 3.5rem;
  ${(props: PageContentProps) =>
    !props.disableGrid &&
    css`
      display: grid;
      grid-column-gap: 1.5rem;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: min-content;
    `}

  @media screen and (min-width: 600px) {
    margin-left: 8vw;
    margin-right: 8vw;
    padding-top: 2rem;
    ${(props: PageContentProps) =>
      !props.disableGrid &&
      css`
        grid-column-gap: 4vw;
        grid-template-columns: repeat(8, 1fr);
      `}
  }

  @media screen and (min-width: 800px) {
    min-height: calc(100vh - 116px);
    margin-left: 8vw;
    margin-right: 8vw;
    ${(props: PageContentProps) =>
      !props.disableGrid &&
      css`
        grid-column-gap: 2rem;
        grid-template-columns: repeat(8, 1fr);
      `}
  }

  @media screen and (min-width: 1024px) {
    margin-left: 3rem;
    margin-right: 3rem;
    min-height: calc(100vh - 3.75rem);
    padding-top: 4.5rem;
    ${(props: PageContentProps) =>
      !props.disableGrid &&
      css`
        grid-column-gap: 2rem;
        grid-template-columns: repeat(12, 1fr);
      `}
  }

  @media screen and (min-width: 1200px) {
    width: 100%;

    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    ${(props: PageContentProps) =>
      !props.disableGrid &&
      css`
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 2rem;
      `}
  }
`

const style = `
width: 100%;
flex-grow: 2;
display: flex;
flex-direction: column;
background-color: ${colors.bgColor};

@media screen and (min-width: ${containerWidths.lg}px) {
  width: calc(100% - ${desktopMenuWidth});
}
`

export const Page = ({ children, className, menu, footer, fullWidthContent, centered, id, disableGrid }: Props) => (
  <PageContainer className={className} id={id}>
    {menu && <Menu />}
    <div css={style}>
      {centered ? (
        <PageContentCentered>{children}</PageContentCentered>
      ) : (
        <PageContent disableGrid={disableGrid}>{children}</PageContent>
      )}
      {fullWidthContent}
      {footer && <Footer />}
    </div>
  </PageContainer>
)
