import React, { useState } from 'react'
import { TextInputBase, ValidationError } from './styles'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  validationError?: string
}

export const InputField = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { onChange, children, ...rest } = props
  const [hasBlur, setHasBlur] = useState(false)
  const [hasChange, setHasChange] = useState(false)

  const isInvalid = !!(hasBlur && props.validationError && props.validationError.length > 0)

  return (
    <>
      <TextInputBase
        {...rest}
        ref={ref}
        isInvalid={isInvalid}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setHasChange(true)
          if (onChange) {
            onChange(e)
          }
        }}
        onBlur={(e) => {
          if (hasChange) {
            setHasBlur(true)
          }
          if (props.onBlur) {
            props.onBlur(e)
          }
        }}
      />
      {children}
      {isInvalid && (
        <ValidationError
          css={`
            flex-basis: 100%;
            margin-top: 0.3125rem;
          `}
        >
          {props.validationError}
        </ValidationError>
      )}
    </>
  )
})
